import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Project, Tier, Milestone, TeamMember } from '../types';
import { ProjectForm } from '../forms/ProjectForm';

interface ProjectDetailsModalProps {
    show: boolean;
    onHide: () => void;
    project: Project | null;
    isEditMode: boolean;
    onToggleEditMode: () => void;
    onUpdateProject: () => void;
    onInputChange: (eventOrValue: string | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onGalleryUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onBackgroundUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onTierChange: (index: number, field: keyof Tier, value: string) => void;
    onAddTier: () => void;
    onRemoveTier: (index: number) => void;
    onMilestoneChange: (index: number, field: keyof Milestone, value: string) => void;
    onAddMilestone: () => void;
    onRemoveMilestone: (index: number) => void;
    onTeamMemberChange: (index: number, field: keyof TeamMember, value: string) => void;
    onAddTeamMember: () => void;
    onRemoveTeamMember: (index: number) => void;
}

export const ProjectDetailsModal: React.FC<ProjectDetailsModalProps> = ({
                                                                            show,
                                                                            onHide,
                                                                            project,
                                                                            isEditMode,
                                                                            onToggleEditMode,
                                                                            onUpdateProject,
                                                                            ...props
                                                                        }) => {
    if (!project || !show) return null;

    return (
        <Modal show={show} onHide={onHide} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>
                    {isEditMode ? "Modifier le projet" : "Détails du projet"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ProjectForm
                    project={project}
                    isEditMode={isEditMode}
                    onClose={onHide}
                    onSave={onUpdateProject}
                    {...props}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Fermer
                </Button>
                {isEditMode ? (
                    <>
                        <Button variant="success" onClick={onUpdateProject}>
                            Enregistrer
                        </Button>
                        <Button variant="outline-secondary" onClick={onToggleEditMode}>
                            Annuler
                        </Button>
                    </>
                ) : (
                    <Button variant="primary" onClick={onToggleEditMode}>
                        Modifier
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
};
