import React, { useState } from 'react';
import { Row, Col, Modal, Carousel } from 'react-bootstrap';

interface GallerySectionProps {
    gallery: string[];
}

export const GallerySection: React.FC<GallerySectionProps> = ({ gallery }) => {
    const [showModal, setShowModal] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);

    console.log("--->", gallery)

    const handleShowModal = (index: number) => {
        setActiveIndex(index);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <div className="bg-cadre project-gallery mt-5">
            <h4 className="text-center">Galerie du Projet</h4>
            <Row className="mt-4 d-flex justify-content-center">
                {gallery.map((image, index) => (
                    <Col md={3} key={index} className="mb-4">
                        <img
                            src={image}
                            alt={`Gallery Image ${index + 1}`}
                            className="img-fluid rounded cursor-pointer"
                            style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
                            onClick={() => handleShowModal(index)}
                        />
                    </Col>
                ))}
            </Row>

            {/* Modal for the gallery */}
            <Modal show={showModal} onHide={handleCloseModal} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Galerie</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Carousel activeIndex={activeIndex} onSelect={setActiveIndex} interval={3000}>
                        {gallery.map((image, index) => (
                            <Carousel.Item key={index}>
                                <img
                                    src={image}
                                    alt={`Gallery Image ${index + 1}`}
                                    className="d-block w-100 rounded"
                                    style={{ maxHeight: '500px', objectFit: 'contain' }}
                                />
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </Modal.Body>
            </Modal>

            <style>{`
                .cursor-pointer {
                    cursor: pointer;
                    transition: transform 0.2s ease;
                }
                .cursor-pointer:hover {
                    transform: scale(1.05);
                }
            `}</style>
        </div>
    );
};
