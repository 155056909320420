// src/components/projects/forms/TierForm.tsx
import React from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { Tier } from '../types';

interface TierFormProps {
    tiers: Tier[];
    isEditMode: boolean;
    onTierChange: (index: number, field: keyof Tier, value: string) => void;
    onAddTier: () => void;
    onRemoveTier: (index: number) => void;
}

export const TierForm: React.FC<TierFormProps> = ({
                                                      tiers,
                                                      isEditMode,
                                                      onTierChange,
                                                      onAddTier,
                                                      onRemoveTier
                                                  }) => {
    return (
        <Form.Group className="mb-3">
            {tiers.map((tier, index) => (
                <div key={index} className="mb-3">
                    <Row>
                        <Col md={4}>
                            <Form.Control
                                type="number"
                                placeholder="Montant (CHF)"
                                value={tier.amount}
                                onChange={(e) => onTierChange(index, 'amount', e.target.value)}
                                readOnly={!isEditMode}
                            />
                        </Col>
                        <Col md={4}>
                            <Form.Control
                                type="text"
                                placeholder="Récompense"
                                value={tier.reward}
                                onChange={(e) => onTierChange(index, 'reward', e.target.value)}
                                readOnly={!isEditMode}
                            />
                        </Col>
                        <Col md={4}>
                            <Form.Control
                                type="number"
                                placeholder="Quantité disponible"
                                value={tier.available_quantity}
                                onChange={(e) => onTierChange(index, 'available_quantity', e.target.value)}
                                readOnly={!isEditMode}
                                min="0"
                            />
                        </Col>
                    </Row>
                    {isEditMode && (
                        <Button variant="danger" size="sm" onClick={() => onRemoveTier(index)} className="mt-2">
                            Supprimer
                        </Button>
                    )}
                </div>
            ))}
            {isEditMode && (
                <Button variant="primary" size="sm" onClick={onAddTier} className="mt-2">
                    Ajouter un palier
                </Button>
            )}
        </Form.Group>
    );
};
