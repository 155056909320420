// src/components/projects/utils/projectHelpers.ts
export const safeParseJSON = (json: any, fallback: any[] = []) => {
    if (Array.isArray(json)) {
        return json;
    }

    try {
        return JSON.parse(json || "[]");
    } catch (error) {
        console.warn("Error parsing JSON:", error);
        return fallback;
    }
};

export const blobUrlToFile = async (blobUrl: string, fileName: string): Promise<File> => {
    const response = await fetch(blobUrl);
    const blob = await response.blob();
    return new File([blob], fileName, { type: blob.type });
};

export const formatProjectData = (project: any) => {
    return {
        title: project.title.trim(),
        description: project.description.trim(),
        target_amount: parseFloat(project.target_amount),
        end_date: new Date(project.end_date).toISOString(),
        type: project.type.trim(),
        background_image: project.background_image,
        video: project.video,
        gallery: JSON.stringify(project.gallery),
        partners: project.partners,
        tiers: project.tiers,
    };
};
