// sections/PartnersSection/index.tsx
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styles from './PartnersSection.module.css';

interface Partner {
    name: string;
    logo: string;
    url: string;
    description?: string;
}

interface PartnersSectionProps {
    partners: Partner[];
}

export const PartnersSection: React.FC<PartnersSectionProps> = ({ partners }) => {
    if (!partners || partners.length === 0) {
        return null;
    }

    return (
        <div className="bg-cadre project-partners mt-5">
            <h4 className="text-center mb-4">Nos Partenaires</h4>
            <Row className="mt-4">
                {partners.map((partner, index) => (
                    <Col
                        md={4}
                        key={index}
                        className="mb-4 text-center partner-item"
                    >
                        <a
                            href={partner.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="partner-link"
                        >
                            <div className="partner-card">
                                <img
                                    src={partner.logo}
                                    alt={partner.name}
                                    className="img-fluid rounded partner-logo"
                                    style={{
                                        maxWidth: '150px',
                                        height: 'auto',
                                        transition: 'transform 0.3s ease'
                                    }}
                                    onError={(e) => {
                                        const target = e.target as HTMLImageElement;
                                        target.src = '/images/placeholder-logo.png'; // Image de repli
                                    }}
                                />
                                <h5 className="mt-3 partner-name">{partner.name}</h5>
                                {partner.description && (
                                    <p className="partner-description">
                                        {partner.description}
                                    </p>
                                )}
                            </div>
                        </a>
                    </Col>
                ))}
            </Row>

            <style>{`
                .partner-card {
                    padding: 1rem;
                    border-radius: 10px;
                    transition: all 0.3s ease;
                    background: rgba(255, 255, 255, 0.05);
                }

                .partner-card:hover {
                    transform: translateY(-5px);
                    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
                }

                .partner-logo:hover {
                    transform: scale(1.05);
                }

                .partner-link {
                    text-decoration: none;
                    color: inherit;
                }

                .partner-name {
                    color: #fff;
                    margin-top: 10px;
                    font-size: 1.1rem;
                }

                .partner-description {
                    font-size: 0.9rem;
                    color: rgba(255, 255, 255, 0.8);
                    margin-top: 5px;
                }

                @media (max-width: 768px) {
                    .partner-card {
                        margin-bottom: 1rem;
                    }
                }
            `}</style>
        </div>
    );
};

export default PartnersSection;
