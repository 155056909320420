import React, { useState } from 'react';
import { Modal, Button, Row, Col, Container, Nav } from 'react-bootstrap';

const TermsAndConditions: React.FC = () => {
    const [show, setShow] = useState(false);
    const [activeTab, setActiveTab] = useState('terms');

    const handleClose = () => setShow(false);
    const handleShow = (tab: string) => {
        setActiveTab(tab);
        setShow(true);
    };

    const renderContent = () => {
        switch (activeTab) {
            case 'terms':
                return (
                    <>
                        <h3>Conditions d'utilisation</h3>
                        <p>Bienvenue sur la plateforme de financement participatif de <strong>3èmelieu SA</strong>, située au <strong>2bis rue Baylon, Genève, Suisse</strong>.</p>

                        <h4>1. Objectif de la plateforme</h4>
                        <p>La plateforme permet aux étudiants de solliciter des financements pour leurs formations au sein de 3èmelieu SA, ainsi que pour des projets éducatifs, artistiques, culturels, ou sociaux.</p>

                        <h4>2. Comptes utilisateur et sécurité</h4>
                        <p>Seuls les étudiants qui souhaitent lancer une campagne de financement doivent créer un compte personnel.</p>

                        <h4>3. Gestion des fonds et objectifs de campagne</h4>
                        <p>Tous les fonds collectés sont directement gérés par <strong>3èmelieu SA</strong>.</p>

                        <h4>4. Droits des contributeurs</h4>
                        <p>Les contributeurs apportent leur soutien en toute confiance, sachant que les fonds sont sous la gestion directe de <strong>3èmelieu SA</strong>.</p>

                        <h4>5. Propriété intellectuelle</h4>
                        <p>Tout le contenu de cette plateforme est protégé et appartient à <strong>3èmelieu SA</strong> ou aux créateurs de projets.</p>

                        <h4>6. Responsabilité et garanties</h4>
                        <p><strong>3èmelieu SA</strong> met tout en œuvre pour offrir une plateforme fiable et sécurisée.</p>

                        <h4>7. Modifications des conditions d'utilisation</h4>
                        <p>Ces conditions peuvent être modifiées à tout moment par <strong>3èmelieu SA</strong>.</p>
                    </>
                );
            case 'privacy':
                return (
                    <>
                        <h3>Politique de confidentialité</h3>
                        <p>Chez <strong>3èmelieu SA</strong>, nous accordons une grande importance à la protection de votre vie privée et à la sécurité de vos données personnelles.</p>

                        <h4>1. Données personnelles collectées</h4>
                        <p>Pour utiliser notre plateforme de crowdfunding, vous devez créer un compte. Les informations suivantes sont requises :</p>
                        <ul>
                            <li>Nom</li>
                            <li>Prénom</li>
                            <li>Adresse email</li>
                        </ul>

                        <h4>2. Cookies</h4>
                        <p>Notre plateforme ne sauvegarde pas de cookies.</p>

                        <h4>3. Utilisation des données</h4>
                        <p>Les données que vous fournissez sont strictement utilisées pour gérer votre compte et les campagnes.</p>

                        <h4>4. Sécurité des données</h4>
                        <p>Nous prenons toutes les mesures raisonnables pour protéger vos données personnelles.</p>

                        <h4>5. Partage des données</h4>
                        <p>Nous ne partageons, ne vendons, ni ne louons vos données personnelles à des tiers.</p>

                        <h4>6. Vos droits</h4>
                        <p>Vous avez le droit d'accéder à vos données personnelles, de les modifier ou de les supprimer à tout moment.</p>

                        <h4>7. Modifications de la politique de confidentialité</h4>
                        <p>Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment.</p>
                    </>
                );
            case 'contact':
                return (
                    <>
                        <h3>Nous contacter</h3>
                        <p>Que vous ayez des questions, des propositions ou simplement l'envie de partager vos réflexions, n'hésitez pas à nous contacter.</p>

                        <h4>Horaires d'ouverture</h4>
                        <ul>
                            <li><strong>Lundi - Vendredi :</strong> 09:00 - 18:00</li>
                            <li><strong>Samedi - Dimanche :</strong> Fermé</li>
                        </ul>

                        <h4>Coordonnées</h4>
                        <p><strong>Adresse :</strong> 2Bis rue Baylon, 1227 Carouge, Genève</p>
                        <p><strong>Email :</strong> <a href="mailto:info@3emelieu.ch">info@3emelieu.ch</a></p>
                        <p><strong>Téléphone :</strong> +41 22 700 03 07</p>
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <>
            <Button variant="link" className="text-primary" onClick={() => handleShow('terms')}>
                Conditions d'utilisation
            </Button>
            <Button variant="link" className="text-primary" onClick={() => handleShow('privacy')}>
                Politique de confidentialité
            </Button>
            {/*
            <Button variant="link" className="text-primary" onClick={() => handleShow('contact')}>
                Nous contacter
            </Button>
            */}

            <Modal show={show} onHide={handleClose} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {activeTab === 'terms' && 'Conditions d\'utilisation'}
                        {activeTab === 'privacy' && 'Politique de confidentialité'}
                        {activeTab === 'contact' && 'Nous contacter'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col md={12}>
                                {renderContent()}
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Fermer</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default TermsAndConditions;
