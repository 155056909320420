// Sidebar.tsx
import React, { useEffect } from "react";
import { Button, NavLink } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { RootState } from "redux/reducers";
import { sidebarToggle, activateMenu } from "redux/actions";
import { useAppDispatch } from "redux/store";
import { MenuItems } from "./MenuItems";

const Sidebar: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const isSidebar = useSelector((state: RootState) => state.template.sidebar);
    const isActiveMenu = useSelector((state: RootState) => state.template.activeMenu);
    const currentUser = useSelector((state: RootState) => state.authToken.userData);

    // Assurez-vous que le userRole n'est jamais undefined
    const userRole = currentUser?.account || '';

    // Debug logs
    console.log('Current user:', currentUser);
    console.log('User role:', userRole);

    const handleActiveMenu = (menuName: string) => {
        console.log('Activating menu:', menuName);
        dispatch(activateMenu(menuName));
    };

    const handleMenuClick = (path: string) => {
        console.log('Navigating to:', path);
        dispatch(activateMenu(path));
        navigate(path, { replace: true });
    };

    const handleSubMenuClick = (path: string) => {
        console.log('Navigating to submenu:', path);
        navigate(path, { replace: true });
    };

    useEffect(() => {
        document.body.classList.toggle('sidebar-toggled', isSidebar);
    }, [isSidebar]);

    // Amélioration de la logique de filtrage des menus
    const filteredMenuItems = MenuItems.filter(item => {
        // Debug logs
        console.log(`Checking menu item: ${item.title}`, {
            requiredRoles: item.requiredRole,
            userRole: userRole,
            hasAccess: !item.requiredRole || item.requiredRole.includes(userRole)
        });

        // Si pas de rôle requis ou si le rôle de l'utilisateur est dans la liste des rôles requis
        return !item.requiredRole || item.requiredRole.includes(userRole);
    });

    return (
        <ul className={`navbar-nav bg-gradient-primary sidebar sidebar-dark accordion d-none d-md-block ${isSidebar ? 'toggled' : ''}`}
            id="accordionSidebar">

            {/* Logo */}
            <div className="mobile-menu-logo">
                <img
                    src="/3emelieu_logo_and_txt_Blanc.png"
                    alt="Logo"
                    style={{maxWidth: "100%", padding: '10px 15% 15% 32px'}}
                />
            </div>

            <hr className="sidebar-divider d-none d-md-block"/>

            {/* Menu Items */}
            {filteredMenuItems.map((item, i) => {
                const Icon = item.icon;

                // Items with submenu
                if (item.subMenu && item.subMenu.length > 0) {
                    return (
                        <li className="nav-item" key={`Menu-${i}`}>
                            <NavLink
                                className={isActiveMenu === item.title ? '' : 'collapsed'}
                                data-toggle="collapse"
                                aria-expanded={isActiveMenu === item.title}
                                onClick={() => handleActiveMenu(item.title)}
                            >
                                <Icon size={24} weight="bold"/>
                                <span>{item.title}</span>
                            </NavLink>

                            <div
                                id="collapseTwo"
                                className={`collapse ${isActiveMenu === item.title ? 'show' : ''}`}
                                aria-labelledby="headingTwo"
                                data-parent="#accordionSidebar"
                            >
                                <div className="bg-white py-2 collapse-inner">
                                    <h6 className="collapse-header">{item.title}:</h6>
                                    {item.subMenu.map((sub, k) => {
                                        const SubIcon = sub.icon;
                                        return (
                                            <Button
                                                key={`SubMenu-${k}`}
                                                variant="link"
                                                className="collapse-item pt-0"
                                                onClick={() => handleSubMenuClick(sub.path)}
                                            >
                                                <SubIcon size={24} weight="bold"/>
                                                <span>{sub.title}</span>
                                            </Button>
                                        );
                                    })}
                                </div>
                            </div>
                            <hr className="sidebar-divider"/>
                        </li>
                    );
                }

                // Items without submenu
                return (
                    <li className={`nav-item ${isActiveMenu === item.path ? 'active' : ''}`} key={`Menu-${i}`}>
                        <Button
                            variant="link"
                            className="nav-link pt-0"
                            onClick={() => handleMenuClick(item.path)}
                        >
                            <Icon size={18} weight="bold"/>
                            <span>{item.title}</span>
                        </Button>
                        <hr className="sidebar-divider"/>
                    </li>
                );
            })}
        </ul>
    );
};

export default Sidebar;
