import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import {
    Modal,
    Button,
    Card,
    Col,
    Row,
    ProgressBar,
    Form,
    OverlayTrigger,
    Tooltip as BootstrapTooltip
} from "react-bootstrap";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    PieChart,
    Pie,
    Cell
} from 'recharts';
import {Info, Clipboard} from "phosphor-react";
import DonorsList from "../donors";
import {getCourses} from "../../services/coursesService";
import dayjs from 'dayjs';
import CustomTooltip from './CustomTooltip'; // Import the custom tooltip

type Props = {
    userData: {
        id: any;
        first_name: any;
        last_name: any;
        email: any;
        account: any;
        reserved_percentage: string;
        usable_funds: string;
        wallet_history: string;
        cursus?: string;
        targetAmount: string;
        daysLeft: string;
        backers: string;
        views: string;
    };
    crowdfundingLink: string;
};

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8084';

const CrowdfundingSection: React.FC<Props> = ({userData, crowdfundingLink}) => {
    const [isCursusEmpty, setIsCursusEmpty] = useState(false);
    const [copyButtonVariant, setCopyButtonVariant] = useState("outline-light");
    const [highlightedCourseData, setHighlightedCourseData] = useState<any>(null);
    const [walletHistory, setWalletHistory] = useState<any[]>([]);
    const [daysLeft, setDaysLeft] = useState<number | null>(null);

    const [calculatedReservedFunds, setCalculatedReservedFunds] = useState(0);
    const [percentageFunded, setPercentageFunded] = useState(0);


    const location = useLocation(); // Access React Router location object
    const [shareUrl, setShareUrl] = useState<string>("");
    const [shareText, setShareText] = useState<string>("");

    // Use location to set share URL and text
    useEffect(() => {
        const currentUrl = `${window.location.origin}${location.pathname}${location.search}`;
        setShareUrl(currentUrl);
        setShareText(`Découvrez cette page : ${currentUrl}`);
    }, [location]); // Ensure the hook reacts to location changes



    const fetchCourseData = async () => {
        try {
            const params = {
                page: 1,
                pageSize: 10,
                searchKey: userData.cursus,
                filters: {}
            };

            const response = await getCourses(params);

            if (response && response.records) {
                const course = response.records.find((course: any) => course.Title.toLowerCase() === userData.cursus.toLowerCase());

                if (course) {
                    setHighlightedCourseData(course);
                }
            }
        } catch (error) {
            console.error('Error fetching course data:', error);
        }
    };

    useEffect(() => {
        if (userData.cursus) {
            fetchCourseData();
        } else {
            setIsCursusEmpty(true);
        }

        try {
            const parsedHistory = JSON.parse(userData.wallet_history);
            let cumulativeBalance = 0;
            const formattedHistory = parsedHistory.map((entry: any) => {
                cumulativeBalance += entry.amount;
                return {
                    date: dayjs(entry.date).format('YYYY-MM-DD HH:mm:ss'), // Format date
                    balance: cumulativeBalance
                };
            });
            setWalletHistory(formattedHistory);
        } catch (error) {
            console.error('Error parsing wallet history:', error);
        }
    }, [userData.cursus, userData.wallet_history]);

    useEffect(() => {
        if (highlightedCourseData && highlightedCourseData.Agenda) {
            try {
                const agendaData = typeof highlightedCourseData.Agenda === 'string'
                    ? JSON.parse(highlightedCourseData.Agenda)
                    : highlightedCourseData.Agenda;

                if (agendaData && agendaData.length > 0) {
                    const startDateString = agendaData[0].StartDate;
                    const startDate = dayjs(startDateString, 'DD.MM.YYYY');
                    const today = dayjs();

                    if (startDate.isValid()) {
                        const diffDays = startDate.diff(today, 'day');
                        setDaysLeft(diffDays);
                    }
                }
            } catch (error) {
                console.error('Erreur lors de l\'analyse de highlightedCourseData.Agenda:', error);
            }
        }
    }, [highlightedCourseData]);

    useEffect(() => {
        const reservedPercentage: number = highlightedCourseData?.RecordType === 'course'
            ? 0 : parseFloat(userData.reserved_percentage);

        // Calcul des fonds réservés
        const reservedFundsCalculated = (parseFloat(userData.targetAmount) * reservedPercentage) / 100;
        setCalculatedReservedFunds(reservedFundsCalculated);

        // Calcul des fonds totaux
        const totalFunds = reservedFundsCalculated + parseFloat(userData.usable_funds);

        // Calcul du pourcentage de financement
        let calculatedPercentage = ((totalFunds / parseFloat(userData.targetAmount)) * 100);

        // Adjust percentage for Diploma and Bachelor types
        if (highlightedCourseData?.Type === 'Diploma') {
            calculatedPercentage /= 2;
        } else if (highlightedCourseData?.Type === 'Bachelor') {
            calculatedPercentage /= 3;
        }

        // Fix to two decimal places and update state
        setPercentageFunded(parseFloat(calculatedPercentage.toFixed(2)));
    }, [userData.reserved_percentage, userData.usable_funds, userData.targetAmount, highlightedCourseData?.Type]);


    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(crowdfundingLink);
        setCopyButtonVariant("success");
        setTimeout(() => {
            setCopyButtonVariant("outline-light");
        }, 2000);
    };

    const data = highlightedCourseData?.RecordType === 'course'
        ? [
            {name: 'Fonds privé', value: parseFloat(userData.usable_funds)}
        ]
        : [
            {name: 'Fonds partenaires', value: calculatedReservedFunds},
            {name: 'Fonds privé', value: parseFloat(userData.usable_funds)}
        ];

    const COLORS = highlightedCourseData?.RecordType === 'course'
        ? ['#1cc88a', '#f6c23e']
        : ['#f6c23e', '#1cc88a'];

    const renderTooltip = (message: string) => (
        <BootstrapTooltip id="button-tooltip">
            {message}
        </BootstrapTooltip>
    );

    let adjustedUsableFunds = calculatedReservedFunds;

    if (highlightedCourseData?.Type === 'Diploma') {
        adjustedUsableFunds /= 2;
    } else if (highlightedCourseData?.Type === 'Bachelor') {
        adjustedUsableFunds /= 3;
    }

    const totalCollected = Math.trunc(adjustedUsableFunds);

    if (isCursusEmpty) {
        return (
            <div className="d-flex flex-column align-items-center mt-3 card-body" style={{marginBottom: 32}}>
                <p className="text-center mb-4" style={{fontSize: '1.7em', lineHeight: '1.5'}}>
                    Pour activer le financement participatif, vous devez d'abord vous inscrire à un cours.<br/> Cliquez
                    sur le
                    bouton ci-dessous pour voir les cours disponibles et vous inscrire.
                </p>
                <Button href="/cours" variant="outline-success" size="lg">
                    Voir les cours
                </Button>
            </div>
        );
    }

    return (
        <>
            <Card className="shadow-lg mb-4 bg-dark text-white">
                <Card.Body>
                    <Row>
                        <Col xs={12} md={6} className="mb-4">
                            <Card className="bg-secondary text-white h-100">
                                <Card.Body>
                                    <div className="text-center">
                                        <div style={{
                                            margin: 10,
                                            padding: 10,
                                            border: '2px solid rgba(255,255,255,0.1',
                                            borderRadius: 16,
                                            animation: 'fadein 1s'
                                        }}>

                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'space-around',
                                                flexDirection: 'row',
                                                alignItems: 'center'
                                            }}>
                                                {highlightedCourseData?.Type && (
                                                    <div style={{
                                                        border: `2px solid ${highlightedCourseData?.Type === 'Certificate' ? '#2ce5ff' :
                                                            highlightedCourseData?.Type === 'Diploma' ? '#FFC107' :
                                                                highlightedCourseData?.Type === 'Bachelor' ? '#ad21f3' : 'transparent'}`,
                                                        backgroundColor: `${highlightedCourseData?.Type === 'Certificate' ? 'rgba(44,229,255,0.5)' :
                                                            highlightedCourseData?.Type === 'Diploma' ? 'rgba(255,193,7,0.5)' :
                                                                highlightedCourseData?.Type === 'Bachelor' ? 'rgba(173,33,243,0.5)' : 'transparent'}`,
                                                        padding: 4,
                                                        borderRadius: 6
                                                    }}>{highlightedCourseData?.Type === 'Certificate' ? 'Certificat' :
                                                        highlightedCourseData?.Type === 'Diploma' ? 'Diplôme' :
                                                            highlightedCourseData?.Type === 'Bachelor' ? 'Bachelor' : highlightedCourseData?.Type}</div>
                                                )}

                                                <h1>{highlightedCourseData?.Title || "Titre non disponible"}</h1>
                                            </div>
                                        </div>
                                        <h4>Financement participatif en cours</h4>
                                        <div className="custom-progress-bar my-3">
                                            <ProgressBar
                                                now={percentageFunded} // Use the updated percentage funded
                                                label={`${Math.floor(percentageFunded)}%`}
                                                className="bg-gradient-success my-3"
                                            />
                                        </div>
                                        <h3>
                                            {totalCollected + parseFloat(userData.usable_funds)} CHF collectés sur {userData.targetAmount} CHF
                                        </h3>
                                        <h4>{daysLeft !== null ? daysLeft : userData.daysLeft} jours restants</h4>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} md={6} className="mb-4">
                            <Card.Body>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: "space-around",
                                    alignItems: 'center',
                                    height: '100%'
                                }}>
                                    <Col xs={12} md={12} className="mb-3">
                                        <Form.Group>
                                            <div style={{
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                flexDirection: 'column'
                                            }}>
                                                <Form.Label className="text-white">URL de la page de financement
                                                    participatif</Form.Label>
                                                <div className="d-flex" style={{width: '100%'}}>
                                                    <Form.Control
                                                        type="text"
                                                        value={crowdfundingLink}
                                                        readOnly
                                                        className="mr-2"
                                                    />
                                                    <Button variant={copyButtonVariant} onClick={handleCopyToClipboard}>
                                                        <Clipboard size={20}/>
                                                    </Button>
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={12} className="mb-3">
                                        <div style={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            flexDirection: 'column'
                                        }}>
                                            <Button
                                                style={{border: '3px solid #00ff8c'}}
                                                variant="outline-success"
                                                href={crowdfundingLink}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                size="lg"
                                            >
                                                Accéder à la page de financement participatif
                                            </Button>
                                        </div>
                                    </Col>
                                </div>
                            </Card.Body>
                            <div style={{marginTop: 4}}>
                                <Card.Body>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: "space-around",
                                        alignItems: 'center',
                                        height: '100%'
                                    }}>
                                        Impressions de la page : {userData.views}
                                    </div>
                                </Card.Body>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={4} className="mb-3">
                            <Card className="bg-secondary text-white h-100">
                                <Card.Body>
                                    <div className="text-s font-weight-bold text-gray-500 text-uppercase mb-2">
                                        Financement participatif
                                    </div>
                                    <div className="h5 mb-0 text-white">
                                        Solde actuel
                                    </div>
                                    <div className="h5 mb-0 text-success">
                                        {highlightedCourseData?.RecordType !== 'course'
                                            ? `${totalCollected + parseFloat(userData.usable_funds)} CHF`
                                            : `${userData.usable_funds} CHF`
                                        }
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        {highlightedCourseData?.RecordType !== 'course' && (
                            <Col xs={12} md={4} className="mb-3">
                                <Card className="bg-secondary text-white h-100">
                                    <Card.Body>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-s font-weight-bold text-gray-500 text-uppercase mb-2">
                                                Fonds partenaires
                                            </div>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={renderTooltip("Les fonds partenaires ne peuvent pas être utilisés pour l'instant.")}
                                            >
                                                <Info size={20}/>
                                            </OverlayTrigger>
                                        </div>
                                        <div className="h5 mb-0 text-warning">
                                            {totalCollected} CHF
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        )}
                        <Col xs={12} md={4} className="mb-3">
                            <Card className="bg-secondary text-white h-100">
                                <Card.Body>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="text-s font-weight-bold text-gray-500 text-uppercase mb-2">
                                            Fonds privé
                                        </div>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={renderTooltip("Les fonds utilisables peuvent être dépensés.")}
                                        >
                                            <Info size={20}/>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="h5 mb-0 text-success">
                                        {userData.usable_funds} CHF
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col xs={12} md={6} className="mb-4">
                            <div className="mb-2 text-gray-500">Répartition des sources de financement</div>
                            <ResponsiveContainer width="100%" height={300}>
                                <PieChart>
                                    <Pie
                                        data={data}
                                        cx="50%"
                                        cy="50%"
                                        labelLine={true}
                                        label={({name, percent}) => `${name} (${(percent * 100).toFixed(0)}%)`}
                                        innerRadius={70}
                                        outerRadius={90}
                                        dataKey="value"
                                    >
                                        {data.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>
                                        ))}
                                    </Pie>
                                    <Tooltip content={<CustomTooltip/>}/>
                                    <Legend/>
                                </PieChart>
                            </ResponsiveContainer>
                        </Col>
                        <Col xs={12} md={6} className="mb-4">
                            <div className="mb-2 text-gray-500">Évolution du solde du portefeuille</div>
                            <ResponsiveContainer width="100%" height={300}>
                                <LineChart
                                    data={walletHistory}
                                    margin={{
                                        top: 15, right: 30, left: 20, bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3"/>
                                    <XAxis
                                        dataKey="date"
                                        stroke="#5257ff"
                                        tick={{fill: '#eee'}}
                                    />
                                    <YAxis
                                        stroke="#5257ff"
                                        tick={{fill: '#eee'}}
                                    />
                                    <Tooltip content={<CustomTooltip/>}/>
                                    <Legend/>
                                    <Line
                                        type="monotone"
                                        dataKey="balance"
                                        stroke="#00ff8c"
                                        strokeWidth={4}
                                        dot={{r: 8}}
                                        activeDot={{r: 8}}
                                    />
                                </LineChart>
                            </ResponsiveContainer>
                        </Col>
                    </Row>
                    <Card.Body>
                        <Row className="mt-6">
                            <Col xs={12} md={12} className="mb-4">
                                <DonorsList userData={userData} shareUrl='user' />
                            </Col>
                        </Row>
                    </Card.Body>
                </Card.Body>
            </Card>
        </>
    );
};

export default CrowdfundingSection;
