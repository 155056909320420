import React, { FC, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers';
import { setJWTToken } from '../../redux/slices/auth';
import { setCurrentUser } from '../../redux/slices/users';

interface AuthenticatedRouteProps {
    element: JSX.Element;
    requiredRole?: string; // Optional role to access this route
}

const AuthenticatedRoute: FC<AuthenticatedRouteProps> = ({ element, requiredRole }) => {
    const dispatch = useDispatch();
    const authToken = useSelector((state: RootState) => state.authToken);
    const [loading, setLoading] = useState(true);
    const now = Date.now();
    const currentUser = useSelector((state: RootState) => state.authToken.userData); // Make sure userData contains the account info

    useEffect(() => {
        const token = localStorage.getItem('jwtToken');
        const expire = localStorage.getItem('expiryDate');
        const storedUserData = localStorage.getItem('userData');

        if (token && expire && parseInt(expire) > now && storedUserData) {
            const userData = JSON.parse(storedUserData);
            if (!authToken.jwtToken) {
                dispatch(setJWTToken({
                    jwtToken: token,
                    expiryDate: expire,
                    userData: userData,
                    errorMessage: ""
                }));
                dispatch(setCurrentUser(userData));
            }
        }
        setLoading(false);
    }, [dispatch, now, authToken.jwtToken]);

    if (loading) {
        return <div>Loading...</div>;
    }

    // Check if user is authenticated and has the required role
    if (authToken.jwtToken && parseInt(authToken.expiryDate) > now) {
        // If a role is required, check if the user has the correct role
        if (requiredRole && currentUser.account !== requiredRole) {
            return <Navigate to="/unauthorized" replace />; // Redirect to unauthorized page if role doesn't match
        }
        return element;
    } else {
        return <Navigate to="/" replace />;
    }
};

export default AuthenticatedRoute;
