// sections/ProgressSection/index.tsx
import React from 'react';
import { Row, Col, ProgressBar } from 'react-bootstrap';
import dayjs from 'dayjs';

interface ProgressSectionProps {
    targetAmount: number;
    raisedFunds: number;
    endDate: string;
    contributorCount: number;
}

export const ProgressSection: React.FC<ProgressSectionProps> = ({
                                                                    targetAmount,
                                                                    raisedFunds,
                                                                    endDate,
                                                                    contributorCount
                                                                }) => {
    const calculateProgress = () => {
        // Calcul du pourcentage de financement
        const percentageFunded = (raisedFunds / targetAmount) * 100;

        // Calcul des jours restants
        const today = dayjs();
        const end = dayjs(endDate);
        const daysLeft = end.diff(today, 'day');

        return {
            percentageFunded: Math.min(percentageFunded, 100),
            daysLeft: Math.max(daysLeft, 0)
        };
    };

    const getDaysRemainingColor = (days: number) => {
        if (days > 10) return '#fff';
        if (days > 5) return '#ffcc00';
        return '#FF9400FF';
    };

    const { percentageFunded, daysLeft } = calculateProgress();

    return (
        <div style={{
            marginBottom: 32,
            padding: 10,
            borderRadius: 10,
            backgroundColor: 'rgba(82,87,255,0.11)',
            border: '2px solid #5257ff'
        }}>
            <Row className="mt-4">
                <Col md={12}>
                    <div className="custom-progress-bar my-3">
                        <ProgressBar
                            now={percentageFunded}
                            label={`${Math.floor(percentageFunded)}%`}
                            className="bg-gradient-success my-3"
                            style={{height: "20px"}}
                        />
                    </div>
                    <p className="text-center" style={{fontSize: '2.0em', fontWeight: 'bold'}}>
                        <strong>{raisedFunds.toLocaleString()}</strong> CHF collectés
                        sur {targetAmount.toLocaleString()} CHF
                    </p>
                </Col>
                <Col md={12} className="text-center mt-4">
                    <div className="d-flex justify-content-around">
                        <span className="infobule-crowdfunding" style={{fontSize: '1.2em'}}>
                            {Math.floor(percentageFunded)}% Financé
                        </span>
                        <span
                            className="infobule-crowdfunding"
                            style={{
                                fontSize: '1.2em',
                                color: getDaysRemainingColor(daysLeft),
                            }}
                        >
                            {daysLeft} Jours restants
                        </span>
                        <span className="infobule-crowdfunding" style={{fontSize: '1.2em'}}>
                            {contributorCount} Contributeurs
                        </span>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

// styles optionnels pour le ProgressSection
const styles = `
.custom-progress-bar .progress-bar {
    background-image: linear-gradient(to right, #5257ff, #00ff8c);
    transition: width 0.5s ease-in-out;
}

.infobule-crowdfunding {
    background: rgba(0, 0, 0, 0.2);
    padding: 8px 16px;
    border-radius: 20px;
    backdrop-filter: blur(4px);
}

.progress {
    background-color: rgba(255, 255, 255, 0.1);
}

.progress-bar {
    font-weight: bold;
    font-size: 1.1em;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}
`;
