// sections/WalletHistorySection.tsx
import React from 'react';
import { Card } from 'react-bootstrap';
import {
    ResponsiveContainer,
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend
} from 'recharts';
import { WalletHistoryItem } from '../types/types';

interface WalletHistorySectionProps {
    history: WalletHistoryItem[];
}

export const WalletHistorySection: React.FC<WalletHistorySectionProps> = ({ history }) => {
    const CustomTooltip = ({ active, payload }: any) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip"
                     style={{backgroundColor: '#333', padding: '10px', borderRadius: '5px', color: '#fff'}}>
                    <p className="label">{`Solde : ${payload[0].value} CHF`}</p>
                </div>
            );
        }
        return null;
    };

    return (
        <Card className="mb-3 bg-secondary text-white" style={{borderRadius: '10px'}}>
            <Card.Body>
                <Card.Title>Historique du portefeuille</Card.Title>

                {history?.length > 0 ? (
                    <ResponsiveContainer width="100%" height={300}>
                        <LineChart
                            data={history}
                            margin={{top: 15, right: 30, left: 20, bottom: 5}}
                        >
                            <CartesianGrid strokeDasharray="2 2" stroke="#ddd"/>
                            <XAxis
                                dataKey="date"
                                stroke="#5257ff"
                                tick={{fill: '#eee'}}
                            />
                            <YAxis
                                stroke="#5257ff"
                                tick={{fill: '#eee'}}
                            />
                            <Tooltip content={<CustomTooltip/>}/>
                            <Legend/>
                            <Line
                                type="monotone"
                                dataKey="amount"
                                stroke="#00ff8c"
                                strokeWidth={4}
                                dot={{r: 8}}
                                activeDot={{r: 8}}
                            />
                        </LineChart>
                    </ResponsiveContainer>
                ) : (
                    <p>Aucun historique de portefeuille disponible.</p>
                )}
            </Card.Body>
        </Card>
    );
};
