import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Project, Tier, Milestone, TeamMember } from '../types';
import { ProjectForm } from '../forms/ProjectForm';

interface CreateProjectModalProps {
    show: boolean;
    onHide: () => void;
    project: Project;
    onCreateProject: () => void;
    onInputChange: (eventOrValue: string | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onGalleryUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onBackgroundUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onTierChange: (index: number, field: keyof Tier, value: string) => void;
    onAddTier: () => void;
    onRemoveTier: (index: number) => void;
    onMilestoneChange: (index: number, field: keyof Milestone, value: string) => void;
    onAddMilestone: () => void;
    onRemoveMilestone: (index: number) => void;
    onTeamMemberChange: (index: number, field: keyof TeamMember, value: string) => void;
    onAddTeamMember: () => void;
    onRemoveTeamMember: (index: number) => void;
}

export const CreateProjectModal: React.FC<CreateProjectModalProps> = ({
                                                                          show,
                                                                          onHide,
                                                                          project,
                                                                          onCreateProject,
                                                                          ...props
                                                                      }) => (
    <Modal show={show} onHide={onHide} size="lg">
        <Modal.Header closeButton>
            <Modal.Title>Créer un nouveau projet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <ProjectForm
                project={project}
                isEditMode={true}
                onClose={onHide}
                onSave={onCreateProject}
                {...props}
            />
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>
                Fermer
            </Button>
            <Button variant="primary" onClick={onCreateProject}>
                Créer le projet
            </Button>
        </Modal.Footer>
    </Modal>
);
