import React, { useState } from "react";
import Layout from "template";
import { Container, Row, Col, Card, Button, Modal, Form, InputGroup } from "react-bootstrap";
import { ArrowUp, ArrowDown, Info, MagnifyingGlass, Funnel, X } from "phosphor-react";
import "./post.css";

// Interfaces
interface ProjectDetails {
    budget: string;
    duration: string;
    impact: string;
    benefits: string;
    status: string;
}

interface Post {
    id: number;
    title: string;
    content: string;
    votes: number;
    category: string;
    userVote: number;
    details: ProjectDetails;
}

interface PostProps extends Post {
    onVote: (id: number, vote: number) => void;
}

interface FilterState {
    search: string;
    category: string;
    sortBy: "votes" | "date";
    sortOrder: "ascending" | "descending";
    dateRange: "all" | "today" | "week" | "month";
}

interface FilterBarProps {
    filters: FilterState;
    onFilterChange: (newFilters: Partial<FilterState>) => void;
}

// Constants
const categories = ["Toutes", "Actualités", "Politique", "Économie", "Environnement"];

const dateRanges = [
    { value: "all", label: "Toutes les dates" },
    { value: "today", label: "Aujourd'hui" },
    { value: "week", label: "Cette semaine" },
    { value: "month", label: "Ce mois" }
];

const initialPosts: Post[] = [
    {
        id: 1,
        title: "Nouvelle ligne de tram",
        content: "La ville annonce la construction d'une nouvelle ligne de tram pour 2025.",
        votes: 0,
        category: "Actualités",
        userVote: 0,
        details: {
            budget: "150 millions CHF",
            duration: "24 mois",
            impact: "Amélioration de la mobilité urbaine",
            benefits: "Réduction du trafic routier, accès facilité aux zones périphériques",
            status: "En phase de planification"
        }
    },
    {
        id: 2,
        title: "Budget cantonal 2024",
        content: "Analyse du nouveau budget cantonal et ses implications pour les citoyens.",
        votes: 0,
        category: "Économie",
        userVote: 0,
        details: {
            budget: "8.5 milliards CHF",
            duration: "12 mois",
            impact: "Gestion des finances publiques",
            benefits: "Investissements dans les services publics",
            status: "En cours de validation"
        }
    }
];

// Composant Post individuel
const PostComponent: React.FC<PostProps> = ({ id, title, content, votes, userVote, details, onVote }) => {
    const [showModal, setShowModal] = useState(false);

    const handleVote = (newVote: number) => {
        if (newVote === userVote) {
            onVote(id, 0);
        } else {
            onVote(id, newVote);
        }
    };

    return (
        <>
            <Card className="post m-3">
                <Row className="g-0">
                    <Col xs={1} className="d-flex flex-column align-items-center justify-content-center vote-bar">
                        <ArrowUp
                            size={36}
                            weight="bold"
                            className={`vote-button vote-up ${userVote === 1 ? 'active' : ''}`}
                            onClick={() => handleVote(1)}
                        />
                        <span className={`votes-count ${votes > 0 ? "text-success" : votes < 0 ? "text-danger" : ""}`}>
                            {votes}
                        </span>
                        <ArrowDown
                            size={36}
                            weight="bold"
                            className={`vote-button vote-down ${userVote === -1 ? 'active' : ''}`}
                            onClick={() => handleVote(-1)}
                        />
                    </Col>
                    <Col xs={11}>
                        <Card.Body>
                            <Card.Title className="post-title mb-3">{title}</Card.Title>
                            <Card.Text className="post-content mb-3">{content}</Card.Text>
                            <Button
                                variant="outline-light"
                                className="info-button"
                                onClick={() => setShowModal(true)}
                            >
                                <Info size={20} className="me-2" />
                                Plus d'informations
                            </Button>
                        </Card.Body>
                    </Col>
                </Row>
            </Card>

            <Modal
                show={showModal}
                onHide={() => setShowModal(false)}
                size="lg"
                contentClassName="modal-3eme-content"
                dialogClassName="modal-3eme-dialog"
            >
                <Modal.Header closeButton className="border-0">
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="project-details p-4">
                        <h5 className="mb-4 text-light">Détails du projet</h5>
                        <div className="detail-item mb-3 bg-3emelieu-2">
                            <strong className="detail-label">Budget:</strong>
                            <span className="detail-value">{details.budget}</span>
                        </div>
                        <div className="detail-item mb-3 bg-3emelieu-2">
                            <strong className="detail-label">Durée estimée:</strong>
                            <span className="detail-value">{details.duration}</span>
                        </div>
                        <div className="detail-item mb-3 bg-3emelieu-2">
                            <strong className="detail-label">Impact:</strong>
                            <span className="detail-value">{details.impact}</span>
                        </div>
                        <div className="detail-item mb-3 bg-3emelieu-2">
                            <strong className="detail-label">Bénéfices:</strong>
                            <span className="detail-value">{details.benefits}</span>
                        </div>
                        <div className="detail-item mb-3 bg-3emelieu-2">
                            <strong className="detail-label">Statut:</strong>
                            <span className="badge bg-primary ms-2">{details.status}</span>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="border-0">
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Fermer
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

// Composant Barre de filtres
const FilterBar: React.FC<FilterBarProps> = ({ filters, onFilterChange }) => {
    const [showFilters, setShowFilters] = useState(false);

    return (
        <div className="filters-section mb-4">
            <InputGroup className="mb-3">
                <Form.Control
                    placeholder="Rechercher dans les discussions..."
                    value={filters.search}
                    onChange={(e) => onFilterChange({ search: e.target.value })}
                    className="search-input"
                />
                <Button
                    variant="outline-light"
                    onClick={() => setShowFilters(!showFilters)}
                    className={showFilters ? 'active' : ''}
                >
                    <Funnel size={20} />
                </Button>
                {filters.search && (
                    <Button
                        variant="outline-light"
                        onClick={() => onFilterChange({ search: '' })}
                    >
                        <X size={20} />
                    </Button>
                )}
            </InputGroup>

            {showFilters && (
                <div className="filters-zone">
                    <Form.Group className="filter-group">
                        <Form.Label className="d-flex align-items-center gap-2">
                            <i className="fas fa-sort"></i>
                            Trier par
                        </Form.Label>
                        <div className="select-wrapper">
                            <Form.Select
                                value={filters.sortBy}
                                onChange={(e) => onFilterChange({sortBy: e.target.value as "votes" | "date"})}
                                className="filter-select"
                            >
                                <option value="votes">Votes</option>
                                <option value="date">Date</option>
                            </Form.Select>
                        </div>
                    </Form.Group>

                    <Form.Group className="filter-group">
                        <Form.Label className="d-flex align-items-center gap-2">
                            <i className="fas fa-arrow-down-wide-short"></i>
                            Ordre
                        </Form.Label>
                        <div className="select-wrapper">
                            <Form.Select
                                value={filters.sortOrder}
                                onChange={(e) => onFilterChange({sortOrder: e.target.value as "ascending" | "descending"})}
                                className="filter-select"
                            >
                                <option value="descending">Décroissant</option>
                                <option value="ascending">Croissant</option>
                            </Form.Select>
                        </div>
                    </Form.Group>

                    <Form.Group className="filter-group">
                        <Form.Label className="d-flex align-items-center gap-2">
                            <i className="fas fa-calendar"></i>
                            Période
                        </Form.Label>
                        <div className="select-wrapper">
                            <Form.Select
                                value={filters.dateRange}
                                onChange={(e) => onFilterChange({dateRange: e.target.value as FilterState["dateRange"]})}
                                className="filter-select"
                            >
                                {dateRanges.map(range => (
                                    <option key={range.value} value={range.value}>
                                        {range.label}
                                    </option>
                                ))}
                            </Form.Select>
                        </div>
                    </Form.Group>
                </div>
            )}
        </div>
    );
};

// Composant principal PostList
const PostList: React.FC = () => {
    const [posts, setPosts] = useState<Post[]>(initialPosts);
    const [filters, setFilters] = useState<FilterState>({
        search: "",
        category: "Toutes",
        sortBy: "votes",
        sortOrder: "descending",
        dateRange: "all"
    });

    const handleFilterChange = (newFilters: Partial<FilterState>) => {
        setFilters(prev => ({...prev, ...newFilters}));
    };

    const handleVote = (id: number, newVote: number) => {
        setPosts(posts.map(post => {
            if (post.id === id) {
                const voteChange = newVote - post.userVote;
                return {
                    ...post,
                    votes: post.votes + voteChange,
                    userVote: newVote
                };
            }
            return post;
        }));
    };

    const filterPosts = () => {
        return posts
            .filter(post => {
                const matchesSearch = post.title.toLowerCase().includes(filters.search.toLowerCase()) ||
                    post.content.toLowerCase().includes(filters.search.toLowerCase());
                const matchesCategory = filters.category === "Toutes" || post.category === filters.category;
                return matchesSearch && matchesCategory;
            })
            .sort((a, b) => {
                if (filters.sortBy === "votes") {
                    return filters.sortOrder === "ascending" ? a.votes - b.votes : b.votes - a.votes;
                } else {
                    return 0;
                }
            });
    };

    const filteredPosts = filterPosts();

    return (
        <Layout>
            <Container fluid className="mt-4 px-4">
                <Row>
                    <Col md={2} className="bg-3emelieu-2 py-4 rounded">
                        <h5 className="mb-4">Catégories</h5>
                        <ul className="list-unstyled">
                            {categories.map(category => (
                                <li key={category} className="mb-2">
                                    <Button
                                        variant="link"
                                        className={`text-decoration-none ${filters.category === category ? "text-primary fw-bold" : "text-white"}`}
                                        onClick={() => handleFilterChange({ category })}
                                    >
                                        {category}
                                    </Button>
                                </li>
                            ))}
                        </ul>
                    </Col>

                    <Col md={10}>
                        <div className="d-flex justify-content-between align-items-center mb-4">
                            <h3>Discussions récentes</h3>
                        </div>

                        <FilterBar
                            filters={filters}
                            onFilterChange={handleFilterChange}
                        />

                        {filteredPosts.length === 0 ? (
                            <div className="text-center py-5">
                                <p>Aucune discussion ne correspond à vos critères de recherche.</p>
                            </div>
                        ) : (
                            filteredPosts.map(post => (
                                <PostComponent
                                    key={post.id}
                                    {...post}
                                    onVote={handleVote}
                                />
                            ))
                        )}
                    </Col>
                </Row>
            </Container>
        </Layout>
    );
};

export default PostList;
