// src/components/projectcrowdfunding/utils.ts
import dayjs from 'dayjs';

export const getYouTubeEmbedUrl = (url: string | undefined) => {
    if (!url) return null;
    const regex = /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regex);
    return match ? `https://www.youtube.com/embed/${match[1]}` : null;
};

export const calculateDaysRemaining = (endDate: string) => {
    const today = dayjs();
    const end = dayjs(endDate);
    const diff = end.diff(today, 'day');
    return diff > 0 ? diff : 0;
};

export const getDaysRemainingColor = (days: number | null) => {
    if (days === null) return '#fff';
    if (days > 10) return '#fff';
    if (days > 5) return '#ffcc00';
    return '#FF9400FF';
};
