import React, { useState } from 'react';

const Contact = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [result, setResult] = useState({
        type: null,
        message: ''
    });

    const onSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        setResult({ type: null, message: '' });

        try {
            const formData = new FormData(event.target);
            formData.append("access_key", "d50ee836-bec5-4d82-8071-400e2a82bdd7");

            const response = await fetch("https://api.web3forms.com/submit", {
                method: "POST",
                body: formData
            });

            const data = await response.json();

            if (data.success) {
                setResult({
                    type: 'success',
                    message: 'Message envoyé avec succès! Nous vous répondrons dans les plus brefs délais.'
                });
                event.target.reset();
            } else {
                throw new Error(data.message || 'Une erreur est survenue');
            }
        } catch (error) {
            setResult({
                type: 'error',
                message: error instanceof Error ? error.message : 'Une erreur est survenue lors de l\'envoi du message'
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="container-fluid py-5">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-8 col-lg-6">
                        <div className="card shadow-sm">
                            <div className="card-body p-4">
                                {/* Header */}
                                    <h4 className="card-title mb-2">Nous contacter</h4>
                                {/* Form */}
                                <form onSubmit={onSubmit}>
                                    <div className="mb-3">
                                        <label htmlFor="name" className="form-label">Nom</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="name"
                                            name="name"
                                            placeholder="Votre nom"
                                            required
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label">Email</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="email"
                                            name="email"
                                            placeholder="votre.email@example.com"
                                            required
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <label htmlFor="message" className="form-label">Message</label>
                                        <textarea
                                            className="form-control"
                                            id="message"
                                            name="message"
                                            placeholder="Votre message..."
                                            required
                                        ></textarea>
                                    </div>

                                    {/* Alert Messages */}
                                    {result.message && (
                                        <div className={`alert ${result.type === 'success' ? 'alert-success' : 'alert-danger'} mb-4`} role="alert">
                                            {result.message}
                                        </div>
                                    )}

                                    {/* Submit Button */}
                                    <button
                                        type="submit"
                                        className="btn btn-primary w-100"
                                        disabled={isLoading}
                                    >
                                        {isLoading ? (
                                            <>
                                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                                Envoi en cours...
                                            </>
                                        ) : (
                                            <>
                                                <i className="bi bi-send me-2"></i>
                                                Envoyer le message
                                            </>
                                        )}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;
