// src/components/projects/ProjectCard.tsx
import React from 'react';
import { Col, Button } from 'react-bootstrap';
import { Project } from './types';

interface ProjectCardProps {
    project: Project;
    onShowDetails: (project: Project) => void;
}

export const ProjectCard: React.FC<ProjectCardProps> = ({ project, onShowDetails }) => {
    return (
        <Col md={4} className="my-3">
            <div className="project-card crowdfunding-card p-3 shadow-sm text-white">
                <h4>{project.title}</h4>
                <p>
                    Objectif : <span className="text-success">{project.target_amount} CHF</span>
                </p>
                <p>Jours Restants : {project.days_left}</p>
                <div className="d-flex justify-content-between align-items-center">
                    <Button
                        variant="outline-light"
                        className="mt-1 me-2"
                        onClick={() => onShowDetails(project)}
                    >
                        Informations
                    </Button>
                    <Button
                        variant="outline-light"
                        className="mt-1 me-2"
                        href={`/project/crowdfunding/${project.id}`}
                    >
                        Voir le projet
                    </Button>
                </div>
            </div>
        </Col>
    );
};
