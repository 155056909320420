// src/components/projects/forms/ProjectForm.tsx
import React from 'react';
import {Form, Row, Col} from 'react-bootstrap';
//import ReactQuill from 'react-quill';
//import 'react-quill/dist/quill.snow.css';
import {ProjectFormProps} from '../types';
import {TierForm} from './TierForm';
import {GalleryPreview} from '../components/GalleryPreview';
import MilestoneForm from './MilestoneForm';
import TeamMemberForm from './TeamMemberForm';

{/*
const quillModules = {
    toolbar: [
        [{'header': [1, 2, false]}],
        ['bold', 'italic', 'underline'],
        [{'list': 'ordered'}, {'list': 'bullet'}],
        ['link'],
        ['clean']
    ],
    clipboard: {matchVisual: false}
};

const quillFormats = [
    'header',
    'bold', 'italic', 'underline',
    'list', 'bullet',
    'link'
];
*/}

export const ProjectForm: React.FC<ProjectFormProps> = ({
                                                            project,
                                                            isEditMode = true,
                                                            onInputChange,
                                                            onGalleryUpload,
                                                            onBackgroundUpload,
                                                            onTierChange,
                                                            onAddTier,
                                                            onRemoveTier,
                                                            onMilestoneChange,
                                                            onAddMilestone,
                                                            onRemoveMilestone,
                                                            onTeamMemberChange,
                                                            onAddTeamMember,
                                                            onRemoveTeamMember
                                                        }) => {
    const handleRemoveImage = (index: number) => {
        const newGallery = [...project.gallery];
        newGallery.splice(index, 1);
        onInputChange({
            target: {name: 'gallery', value: newGallery}
        } as any);
    };

    return (
        <Form className="project-form">
            <div className="form-section">

                {/* Titre */}
                <div className=" bg-cadre">
                    <h4 className="section-title" style={{textAlign: 'center'}}>Titre du projet</h4>
                    <Form.Group className="mb-4">
                        <Form.Control
                            type="text"
                            name="title"
                            value={project.title}
                            onChange={onInputChange}
                            readOnly={!isEditMode}
                            placeholder="Entrez le titre du projet"
                            className="form-control-lg"
                        />
                    </Form.Group>
                </div>

                {/* Description */}
                <div className=" bg-cadre">
                    <h4 className="section-title" style={{textAlign: 'center'}}>Description détaillée</h4>
                    <Form.Group className="mb-4">
                        {/* <div className={`quill-wrapper ${!isEditMode ? 'readonly' : ''}`}>
                            <ReactQuill
                                theme="snow"
                                value={project.description}
                                onChange={(content) => onInputChange(content)}
                                modules={quillModules}
                                formats={quillFormats}
                                readOnly={!isEditMode}
                                placeholder="Décrivez votre projet en détail..."
                                className="custom-quill"
                            />

                        </div>*/}
                    </Form.Group>
                </div>

                {/* Montant et Date */}
                <div className=" bg-cadre">
                    <h4 className="section-title" style={{textAlign: 'center'}}>Médias du projet</h4>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-4">
                                <Form.Label>Objectif financier (CHF)</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="target_amount"
                                    value={project.target_amount}
                                    onChange={onInputChange}
                                    readOnly={!isEditMode}
                                    className="form-control-lg"
                                    min="0"
                                    step="100"
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-4">
                                <Form.Label>Date de fin</Form.Label>
                                <Form.Control
                                    type="date"
                                    name="end_date"
                                    value={project.end_date}
                                    onChange={onInputChange}
                                    readOnly={!isEditMode}
                                    className="form-control-lg"
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </div>
            </div>

            {/* Section Médias */}
            <div className="form-section bg-cadre">
                <h4 className="section-title" style={{textAlign: 'center'}}>Médias du projet</h4>

                <Form.Group className="mb-4">
                    <Form.Label>Image principale</Form.Label>
                    {isEditMode && (
                        <div className="upload-main-image">
                            <Form.Control
                                type="file"
                                accept="image/*"
                                onChange={onBackgroundUpload}
                                className="file-input"
                            />
                            <small className="text-muted">
                                Format recommandé : 1920x1080px, max 5MB
                            </small>
                        </div>
                    )}
                    {project.background_image && (
                        <div className="background-preview mt-3">
                            <div className="image-container">
                                <img
                                    src={project.background_image}
                                    alt="Image principale"
                                    className="background-image"
                                />
                            </div>
                            {isEditMode && (
                                <button
                                    type="button"
                                    className="btn btn-danger btn-sm mt-2"
                                    onClick={() => onInputChange({
                                        target: {name: 'background_image', value: ''}
                                    } as any)}
                                >
                                    Supprimer l'image
                                </button>
                            )}
                        </div>
                    )}
                </Form.Group>

                <GalleryPreview
                    images={project.gallery}
                    isEditMode={isEditMode}
                    onGalleryUpload={onGalleryUpload}
                    onRemoveImage={isEditMode ? handleRemoveImage : undefined}
                />
            </div>

            {/* Section Paliers */}
            <div className="form-section bg-cadre">
                <h4 className="section-title" style={{textAlign: 'center'}}>Paliers de récompense</h4>
                <TierForm
                    tiers={project.tiers}
                    isEditMode={isEditMode}
                    onTierChange={onTierChange}
                    onAddTier={onAddTier}
                    onRemoveTier={onRemoveTier}
                />
            </div>

            {/* Section Milestones */}
            <div className="form-section bg-cadre">
                <h4 className="section-title" style={{textAlign: 'center'}}>Étapes clés</h4>
                <MilestoneForm
                    milestones={project.milestones}
                    isEditMode={isEditMode}
                    onMilestoneChange={onMilestoneChange}
                    onAddMilestone={onAddMilestone}
                    onRemoveMilestone={onRemoveMilestone}
                />
            </div>

            {/* Section Notre Équipe */}
            <div className="form-section bg-cadre">
                <h4 className="section-title" style={{textAlign: 'center'}}>Notre Équipe</h4>
                {project.teamMembers.map((teamMember, index) => (
                    <TeamMemberForm
                        key={index}
                        teamMember={teamMember}
                        index={index}
                        onTeamMemberChange={onTeamMemberChange}
                        onRemoveTeamMember={onRemoveTeamMember}
                    />
                ))}
            </div>
        </Form>
    );
};

export default ProjectForm;
