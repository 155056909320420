import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Image } from 'react-bootstrap';

interface TeamMember {
    name: string;
    role: string;
    photoUrl: string;
}

interface TeamProps {
    members: string | TeamMember[];  // members peut être une chaîne JSON ou un tableau d'objets TeamMember
}

const Team: React.FC<TeamProps> = ({ members }) => {
    const [parsedMembers, setParsedMembers] = useState<TeamMember[]>([]);

    useEffect(() => {
        if (typeof members === 'string') {
            try {
                const parsedData = JSON.parse(members);
                if (Array.isArray(parsedData) && parsedData.length > 0) {
                    setParsedMembers(parsedData);
                } else {
                    console.error('Données invalides ou vides : les membres doivent être un tableau d\'objets non vide.');
                }
            } catch (error) {
                console.error('Erreur lors du parsing des données de l\'équipe :', error);
            }
        } else if (Array.isArray(members) && members.length > 0) {
            setParsedMembers(members);
        } else {
            console.error('Données invalides ou vides : `members` doit être soit une chaîne JSON non vide, soit un tableau d\'objets non vide.');
        }
    }, [members]);

    if (parsedMembers.length === 0) {
        return null;
    }

    return (
        <div className="my-5 bg-cadre">
            <h4>Notre Équipe</h4>
            <Row>
                {parsedMembers.map((member, index) => (
                    <Col md={4} key={index} className="mb-4">
                        <Card className="text-center">
                            <Card.Body>
                                <div className="mb-3">
                                    <Image
                                        src={member.photoUrl}
                                        roundedCircle
                                        alt={member.name}
                                        width="120"
                                        height="120"
                                        onError={(e) => {
                                            e.currentTarget.src = '/path/to/fallback/image.jpg';
                                        }}
                                    />
                                </div>
                                <Card.Title>{member.name}</Card.Title>
                                <Card.Subtitle className="text-muted">{member.role}</Card.Subtitle>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default Team;
