// HeroSection.tsx
import React from 'react';
import { Row, Col, ProgressBar } from 'react-bootstrap';
import { calculateDaysRemaining, getDaysRemainingColor, getYouTubeEmbedUrl } from '../utils';

interface HeroSectionProps {
    title: string;
    description: string;
    video?: string;
    targetAmount: number;
    raisedFunds: number;
    endDate: string;
    contributorCount: number;
}

export const HeroSection: React.FC<HeroSectionProps> = ({
                                                            title,
                                                            description,
                                                            video,
                                                            targetAmount,
                                                            raisedFunds,
                                                            endDate,
                                                            contributorCount,
                                                        }) => {
    const percentageFunded = (raisedFunds / targetAmount) * 100;
    const daysRemaining = calculateDaysRemaining(endDate);

    return (
        <div className="text-center my-4">
            {/* Hero Section */}
            <div style={{ marginBottom: 60, marginTop: 30 }}>
                <h3 className="fw-bold">
                    Soutenez le projet :
                </h3>
                <h1><span style={{ color: '#00ff8c' }}>{title}</span></h1>
            </div>
            <div className="bg-cadre" dangerouslySetInnerHTML={{ __html: description }}>
            </div>

            {video && getYouTubeEmbedUrl(video) && (
                <div className="text-center mb-4">
                    <iframe
                        width="100%"
                        height="400"
                        src={getYouTubeEmbedUrl(video)}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        style={{ borderRadius: '10px' }}
                    />
                </div>
            )}
        </div>
    );
};
