import React, { useState } from 'react';
import { Row, Col, Card, Button, Form, Modal } from 'react-bootstrap';
import {loadStripe} from "@stripe/stripe-js";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '');
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8084';

interface Tier {
    id: string;
    amount: number;
    reward: string;
    description?: string;
    available_quantity: number;
    minimum_amount: number;
    current_contributors?: number;
    estimated_delivery_date?: string;
    shipping_cost?: number;
}

interface WalletHistoryItem {
    date: string;
    amount: number;
    type: string;
}

interface Partner {
    name: string;
    logo: string;
    url: string;
}

interface ProjectData {
    id: number;
    title: string;
    description: string;
    target_amount: number;
    raised_funds: number;
    end_date: string;
    status: string;
    background_image: string;
    video?: string;
    gallery?: string[];
    partners?: Partner[];
    tiers?: Tier[];
    wallet_history: WalletHistoryItem[];
    backers?: number;
    creator_id: number;
    category: string;
    start_date: string;
    detailed_description: string;
    minimum_contribution: number;
    website_url: string;
    approval_status: string;
    comments?: any[];
    contributor_count: number;
    updates?: any[];
    tags?: string[];
    priority_level?: string;
    milestones?: any[];
    team_members?: any[];
    contact_information: string;
}

interface DonationSectionProps {
    minimumAmount: number;
    tiers: Tier[];
    projectData: ProjectData;
    onDonate: (donationData: {
        amount: number;
        donorName: string;
        isAnonymous: boolean;
        selectedTierId?: string;
    }) => void;
}

const DonationSection: React.FC<DonationSectionProps> = ({
                                                             minimumAmount,
                                                             tiers,
                                                             onDonate,
                                                             projectData,
                                                         }) => {
    const [donorName, setDonorName] = useState<string>('');
    const [isAnonymous, setIsAnonymous] = useState<boolean>(false);
    const [selectedTier, setSelectedTier] = useState<Tier | null>(null);
    const [selectedAmount, setSelectedAmount] = useState<number | null>(null);
    const [showModalInfos, setShowModalInfos] = useState(false);
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [error, setError] = useState<string | null>(null);

    const handleTierSelect = (tier: Tier) => {
        setSelectedTier(tier); // Marquer ce palier comme sélectionné
        setSelectedAmount(tier.amount); // Mettre à jour le montant sélectionné
    };

    const handleShowModalInfos = (index: number) => {
        setActiveIndex(index);
        setShowModalInfos(true);
    };

    const handleDonate = async () => {
        if (!selectedAmount || selectedAmount < minimumAmount) {
            setError(`Le montant minimum est de ${minimumAmount} CHF.`);
            return;
        }

        if (!isAnonymous && donorName.trim() === '') {
            setError('Veuillez entrer votre nom ou choisir de donner anonymement.');
            return;
        }

        setError(null);

        try {
            // Conversion en nombre et multiplication par 100 pour les centimes
            const amountInCents = Math.round(Number(selectedAmount) * 100);

            const requestBody = {
                amount: amountInCents, // Montant en centimes pour Stripe
                projectId: projectData?.id,
                projectTitle: projectData?.title,
                donorName: isAnonymous ? 'Anonyme' : donorName,
                selectedTierId: selectedTier?.id
            };

            console.log("Sending request with body:", requestBody);

            const response = await fetch(`${API_BASE_URL}/create-checkout-session`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || `Network response was not ok: ${response.statusText}`);
            }

            const session = await response.json();
            const stripe = await stripePromise;

            if (!stripe) {
                throw new Error('Stripe n\'a pas pu être initialisé');
            }

            const { error } = await stripe.redirectToCheckout({
                sessionId: session.id,
            });

            if (error) {
                throw error;
            }
        } catch (error: any) {
            console.error('Payment error:', error);
            setError(error.message || 'Une erreur est survenue lors du traitement du paiement');
        }
    };

    return (
        <div className="bg-cadre">
            <h2 className="text-center mt-5">Choisissez un palier de soutien</h2>
            <Row className="mt-4">
                {tiers.map((tier, index) => (
                    <Col md={6} key={tier.id} className="mb-4">
                        <Card
                            className={`tier-card ${
                                selectedTier?.id === tier.id ? 'selected' : ''
                            }`} // Ajoute la classe 'selected' au palier cliqué
                        >
                            <Card.Body>
                                <div
                                    style={{
                                        border: selectedTier?.amount === tier.amount ? '2px solid rgb(0, 255, 140)' : 'none',
                                        borderRadius: '10px',
                                        padding: '10px',
                                        boxShadow: selectedTier?.amount === tier.amount ? '0 0 128px rgba(0, 255, 140, 0.6)' : 'none',
                                    }}
                                    id={`tier-${index}`}
                                    onClick={() => handleTierSelect(tier)}
                                >
                                    <h3
                                        className="text-center"
                                        style={{
                                            fontSize: '2.5em',
                                            color: 'rgb(0, 255, 140)',
                                        }}
                                    >
                                        {tier.amount} CHF -{' '}
                                        {tier.reward || 'Palier sans nom'}
                                    </h3>
                                    <p>{tier.description || 'Description indisponible.'}</p>
                                    {/* Quantité disponible */}
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'space-around'
                                    }}>
                                        <h5 className="badge bg-cadre"
                                            style={{fontSize: '1.2em'}}>
                                            Quantité disponible
                                            : {tier.available_quantity || 'Non spécifié'}
                                        </h5>
                                    </div>
                                    <div className="d-flex justify-content-around mt-3">
                                        <Button
                                            variant="info"
                                            size="lg"
                                            className="rounded-pill"
                                            style={{padding: '4px 14px'}}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleShowModalInfos(index);
                                            }}
                                        >
                                            Informations
                                        </Button>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>

            {/* Modal pour les informations sur les paliers */}
            <Modal
                show={showModalInfos}
                onHide={() => setShowModalInfos(false)}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Informations du palier</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {tiers[activeIndex] && (
                        <>
                            <h3>
                                {tiers[activeIndex].amount} CHF -{' '}
                                {tiers[activeIndex].reward || 'Palier sans nom'}
                            </h3>
                            <p>{tiers[activeIndex].description}</p>
                            {tiers[activeIndex].estimated_delivery_date && (
                                <p>
                                    Date de livraison estimée :{' '}
                                    {tiers[activeIndex].estimated_delivery_date}
                                </p>
                            )}
                            {tiers[activeIndex].shipping_cost && (
                                <p>Frais de livraison : {tiers[activeIndex].shipping_cost} CHF</p>
                            )}
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModalInfos(false)}>
                        Fermer
                    </Button>
                </Modal.Footer>
            </Modal>

            <Row className="text-center mt-4">
                <Col xs={12}>
                    <Form>
                        <Form.Group controlId="formDonorName" className="mb-3">
                            <Form.Label>Nom du contributeur</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Entrez votre nom"
                                value={donorName}
                                onChange={(e) => setDonorName(e.target.value)}
                                disabled={isAnonymous}
                                style={{ borderRadius: '10px', padding: '0.75rem' }}
                            />
                        </Form.Group>
                        <Form.Check
                            type="checkbox"
                            checked={isAnonymous}
                            onChange={(e) => setIsAnonymous(e.target.checked)}
                            label="Donner anonymement"
                            className="fs-5"
                        />
                    </Form>
                    <Button
                        onClick={handleDonate}
                        className="btn btn-success btn-lg rounded-pill px-5 py-3 mt-4"
                        disabled={!selectedAmount}
                        style={{
                            marginTop: 32,
                            marginBottom: 32,
                            borderRadius: '50px',
                        }}
                    >
                        Soutenir ce projet
                    </Button>
                    {error && <p className="text-danger mt-3">{error}</p>}
                </Col>
            </Row>
        </div>
    );
};

export default DonationSection;
