import React from 'react';
import { Form, Button } from 'react-bootstrap';

interface TeamMember {
    name: string;
    role: string;
    bio: string;
}

interface TeamMemberFormProps {
    teamMember: TeamMember;
    index: number;
    onTeamMemberChange: (index: number, field: keyof TeamMember, value: string) => void;
    onRemoveTeamMember: (index: number) => void;
}

const TeamMemberForm: React.FC<TeamMemberFormProps> = ({
                                                           teamMember,
                                                           index,
                                                           onTeamMemberChange,
                                                           onRemoveTeamMember
                                                       }) => {
    return (
        <div className="team-member-form mb-3">
            <Form.Group>
                <Form.Label>Nom</Form.Label>
                <Form.Control
                    type="text"
                    value={teamMember.name}
                    onChange={(e) => onTeamMemberChange(index, 'name', e.target.value)}
                />
            </Form.Group>
            <Form.Group>
                <Form.Label>Rôle</Form.Label>
                <Form.Control
                    type="text"
                    value={teamMember.role}
                    onChange={(e) => onTeamMemberChange(index, 'role', e.target.value)}
                />
            </Form.Group>
            <Form.Group>
                <Form.Label>Biographie</Form.Label>
                <Form.Control
                    as="textarea"
                    rows={3}
                    value={teamMember.bio}
                    onChange={(e) => onTeamMemberChange(index, 'bio', e.target.value)}
                />
            </Form.Group>
            <Button variant="danger" onClick={() => onRemoveTeamMember(index)}>
                Supprimer ce membre
            </Button>
        </div>
    );
};

export default TeamMemberForm;
