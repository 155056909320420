import React from 'react';
import { Card, Button } from 'react-bootstrap';

interface DisplayURLProps {
    url: string;
}

const DisplayURL: React.FC<DisplayURLProps> = ({ url }) => {
    if (!url || url.trim() === '') {
        return null;
    }

    return (
        <Card className="my-3 bg-cadre">
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <Button variant="primary" href={url} target="_blank" rel="noopener noreferrer" style={{maxWidth: 320}}>
                    Visiter le site du projet
                </Button>
            </div>
        </Card>
    );
};

export default DisplayURL;
