import { House, Book, Kanban, ChatsCircle } from "phosphor-react";

export const MenuItems = [
    { title: ' Dashboard', path: '/dashboard', icon: House, subMenu: [], requiredRole: ['admin', 'student'] },
    { title: ' Projects', path: '/projects', icon: Kanban, subMenu: [], requiredRole: ['admin'] },
    { title: ' Cours', path: '/cours', icon: Book, subMenu: [], requiredRole: ['admin', 'student']},
];

{/* { title: ' Forum', path: '/talkhive', icon: ChatsCircle, subMenu: [] },
{ title: ' Reddit', path: '/upvote', icon: ChatsCircle, subMenu: [] },*/}
