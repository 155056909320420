import "./styles/3emelieu/app.scss";
import "./styles/3emelieu/global.scss";
import { Provider } from "react-redux";
import store from "redux/store";
import Pages from "pages";
import { useState, useEffect } from 'react';

function App(): JSX.Element {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => setIsLoaded(true), 100); // Ajoutez un délai de 100ms
  }, []);

  if (!isLoaded) {
    return null; // ou un composant de chargement
  }

  return (
    <Provider store={store}>
      <div className="App">
        <Pages />
      </div>
    </Provider >
  );
}

export default App;
