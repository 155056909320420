import React from 'react';
import { Table } from 'react-bootstrap';

interface Donor {
    id: number;
    name: string;
    amount: number;
    date: string;
    isAnonymous: boolean;
}

interface DonorListProps {
    donors: Donor[];
}

const DonorList: React.FC<DonorListProps> = ({ donors }) => {
    return (
        <div>
            <h4 className="text-center mb-4">Liste des Donateurs</h4>
            {donors.length > 0 ? (
                <Table hover>
                    <thead>
                    <tr>
                        <th>Nom</th>
                        <th>Montant</th>
                        {/* <th>Date</th> */}
                    </tr>
                    </thead>
                    <tbody>
                    {donors.map((donor) => (
                        <tr key={donor.id}>
                            <td style={{color: '#fff'}}>{donor.isAnonymous ? 'Anonyme' : donor.name}</td>
                            <td style={{color: '#fff'}}>{donor.amount} CHF</td>
                            {/* <td style={{color: '#fff'}}>{new Date(donor.date).toLocaleDateString()}</td> */}
                        </tr>
                    ))}
                    </tbody>
                </Table>
            ) : (
                <p className="text-center">Aucun donateur pour l'instant.</p>
            )}
        </div>
    );
};

export default DonorList;
