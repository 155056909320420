import React from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { Milestone } from '../types';

interface MilestoneFormProps {
    milestones: Milestone[];
    isEditMode: boolean;
    onMilestoneChange: (index: number, field: keyof Milestone, value: string) => void;
    onAddMilestone: () => void;
    onRemoveMilestone: (index: number) => void;
}

const MilestoneForm: React.FC<MilestoneFormProps> = ({
                                                         milestones,
                                                         isEditMode,
                                                         onMilestoneChange,
                                                         onAddMilestone,
                                                         onRemoveMilestone
                                                     }) => {
    return (
        <div className="milestones-form">
            {milestones.map((milestone, index) => (
                <div key={index} className="milestone-item mb-4">
                    <Row>
                        <Col md={4}>
                            <Form.Group>
                                <Form.Label>Titre</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={milestone.title}
                                    onChange={(e) => onMilestoneChange(index, 'title', e.target.value)}
                                    readOnly={!isEditMode}
                                    placeholder="Titre de l'étape"
                                    className="form-control-lg"
                                />
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group>
                                <Form.Label>Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    value={milestone.target_date}
                                    onChange={(e) => onMilestoneChange(index, 'target_date', e.target.value)}
                                    readOnly={!isEditMode}
                                    className="form-control-lg"
                                />
                            </Form.Group>
                        </Col>
                        <Col md={4} className="d-flex align-items-end">
                            {isEditMode && (
                                <Button
                                    variant="danger"
                                    className="mb-3"
                                    onClick={() => onRemoveMilestone(index)}
                                >
                                    Supprimer
                                </Button>
                            )}
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <Form.Group>
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={2}
                                    value={milestone.description}
                                    onChange={(e) => onMilestoneChange(index, 'description', e.target.value)}
                                    readOnly={!isEditMode}
                                    placeholder="Description détaillée de l'étape"
                                    className="form-control-lg"
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    {isEditMode && (
                        <Row className="mt-3">
                            <Col>
                                <Form.Group>
                                    <Form.Label>Statut</Form.Label>
                                    <Form.Select
                                        value={milestone.status}
                                        onChange={(e) => onMilestoneChange(index, 'status', e.target.value)}
                                        className="form-control-lg"
                                    >
                                        <option value="pending">En attente</option>
                                        <option value="in_progress">En cours</option>
                                        <option value="completed">Terminé</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                    )}
                </div>
            ))}

            {isEditMode && (
                <Button variant="primary" onClick={onAddMilestone}>
                    Ajouter une étape
                </Button>
            )}
        </div>
    );
};

export default MilestoneForm;
