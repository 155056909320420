import React from 'react';
import Layout from "template";
import {Container, Row, Col} from "react-bootstrap";

const Unauthorized: React.FC = () => {
    return (
        <Layout>
            <Container fluid className="px-4 mb-4">
                <Row>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', textAlign: 'center'}}>
                        <div>
                            <h1>Non autorisé</h1>
                            <p>Vous n'avez pas la permission de voir cette page.</p>
                        </div>
                    </div>
                </Row>
            </Container>
        </Layout>
    );
};

export default Unauthorized;
