// services/projectService.tsx
import { useState } from 'react';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8084';

// Fetch projects from the API
export const getProjects = async () => {
    try {
        const response = await fetch(`${API_BASE_URL}/projects`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
            }
        });

        if (!response.ok) {
            throw new Error(`Error fetching projects: ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching projects:', error);
        throw error;
    }
};

// Create a new project
export const createProject = async (projectData: any) => {
    console.log(projectData)
    try {
        const response = await fetch(`${API_BASE_URL}/projects`, { // Mettre à jour l'URL ici
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
            },
            body: JSON.stringify(projectData),
        });

        if (!response.ok) {
            const errorDetails = await response.text();
            throw new Error(`Project creation failed: ${errorDetails}`);
        }

        return await response.json();
    } catch (error) {
        console.error('Error creating project:', error);
        throw error;
    }
};

// Update an existing project
export const updateProject = async (projectId: number, projectData: any) => {
    try {
        // Conversion de `end_date` au format attendu par MySQL
        if (projectData.end_date) {
            projectData.end_date = new Date(projectData.end_date).toISOString().slice(0, 10); // YYYY-MM-DD
        }

        // Convertir les champs JSON en chaînes
        if (projectData.gallery && typeof projectData.gallery !== 'string') {
            projectData.gallery = JSON.stringify(projectData.gallery);
        }
        if (projectData.tiers && typeof projectData.tiers !== 'string') {
            projectData.tiers = JSON.stringify(projectData.tiers);
        }
        if (projectData.partners && typeof projectData.partners !== 'string') {
            projectData.partners = JSON.stringify(projectData.partners);
        }

        const response = await fetch(`${API_BASE_URL}/projects/${projectId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`,
            },
            body: JSON.stringify(projectData),
        });

        if (!response.ok) {
            throw new Error(`Error updating project: ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error updating project:', error);
        throw error;
    }
};

// Upload an image
export const uploadImage = async (imageFile) => {
    if (!imageFile) {
        throw new Error("No image file provided");
    }

    const formData = new FormData();
    formData.append("file", imageFile); // Attache le fichier avec la clé 'file'

    console.log("FormData keys:", Array.from(formData.keys()));
    console.log("FormData file content:", formData.get("file"));

    try {
        const response = await fetch(`${API_BASE_URL}/projects/uploadFile`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("jwtToken")}`, // Ajouter le token JWT
            },
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`Error uploading image: ${response.statusText}`);
        }

        const data = await response.json();
        return data.fileUrl; // Retourne l'URL du fichier téléchargé
    } catch (error) {
        console.error("Error uploading image:", error);
        throw error;
    }
};
