import React from 'react';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';

interface GalleryPreviewProps {
    images: string[];
    isEditMode: boolean;
    onGalleryUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onRemoveImage?: (index: number) => void;
}

const GalleryContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
`;

const GalleryImage = styled.img`
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 4px;
`;

const RemoveButton = styled.button`
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: rgba(255, 255, 255, 0.7);
    border: none;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: bold;
    &:hover {
        background-color: rgba(255, 0, 0, 0.7);
        color: white;
    }
`;

const ImageWrapper = styled.div`
    position: relative;
`;

export const GalleryPreview: React.FC<GalleryPreviewProps> = ({
    images,
    isEditMode,
    onGalleryUpload,
    onRemoveImage
}) => {
    return (
        <div>
            <Form.Group>
                <Form.Label>Galerie d'images</Form.Label>
                {isEditMode && (
                    <Form.Control
                        type="file"
                        accept="image/*"
                        onChange={onGalleryUpload}
                        multiple
                    />
                )}
            </Form.Group>
            <GalleryContainer>
                {images.map((image, index) => (
                    <ImageWrapper key={index}>
                        <GalleryImage src={image} alt={`Gallery image ${index + 1}`} />
                        {isEditMode && onRemoveImage && (
                            <RemoveButton onClick={() => onRemoveImage(index)}>
                                ×
                            </RemoveButton>
                        )}
                    </ImageWrapper>
                ))}
            </GalleryContainer>
        </div>
    );
};
