import React, { useState, useEffect } from "react";
import Layout from "template";
import { Container, Row, Button } from "react-bootstrap";
import { getProjects, createProject, updateProject } from "../../services/projectService";
import { Project, Tier, TeamMember, Milestone } from './types';
import { ProjectCard } from "./ProjectCard";
import { CreateProjectModal } from "./modals/CreateProjectModal";
import { ProjectDetailsModal } from "./modals/ProjectDetailsModal";
import { safeParseJSON, formatProjectData, blobUrlToFile } from "./utils/projectHelpers";
import "./projects.css";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8084";

const emptyProject: Project = {
    title: "",
    description: "",
    target_amount: "",
    end_date: "",
    type: "",
    background_image: "",
    video: "",
    gallery: [],
    partners: [],
    tiers: [],
    milestones: [],
    teamMembers: []  // Add this line
};

const Projects: React.FC = () => {
    const [projects, setProjects] = useState<Project[]>([]);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [selectedProject, setSelectedProject] = useState<Project | null>(null);
    const [newProject, setNewProject] = useState<Project>(emptyProject);
    const [isEditMode, setIsEditMode] = useState(false);
    const [selectedImage, setSelectedImage] = useState<File | null>(null);
    const [galleryFiles, setGalleryFiles] = useState<File[]>([]);

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await getProjects();
                setProjects(response);
            } catch (error) {
                console.error("Erreur lors de la récupération des projets :", error);
            }
        };
        fetchProjects();
    }, []);

    const handleShowDetails = (project: Project) => {
        const parsedGallery = Array.isArray(project.gallery)
            ? project.gallery
            : safeParseJSON(project.gallery, []);

        const parsedTiers = Array.isArray(project.tiers)
            ? project.tiers
            : safeParseJSON(project.tiers, []);

        const parsedMilestones = Array.isArray(project.milestones)
            ? project.milestones
            : safeParseJSON(project.milestones, []);

        setSelectedProject({
            ...project,
            gallery: parsedGallery,
            tiers: parsedTiers,
            milestones: parsedMilestones
        });
        setShowDetailsModal(true);
    };

    const handleInputChange = (
        eventOrValue: string | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const updateState = (prevState: any) => {
            if (typeof eventOrValue === "string") {
                if (prevState.description === eventOrValue) return prevState;
                return { ...prevState, description: eventOrValue };
            } else if (eventOrValue?.target) {
                const { name, value } = eventOrValue.target;
                return { ...prevState, [name]: value };
            }
            return prevState;
        };

        if (isEditMode) {
            setSelectedProject(prev => prev ? updateState(prev) : null);
        } else {
            setNewProject(updateState);
        }
    };

    const handleGalleryUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const filesArray = Array.from(e.target.files);
            setGalleryFiles(prev => [...prev, ...filesArray]);

            const galleryPreviews = filesArray.map(file => URL.createObjectURL(file));
            const updateState = (prev: any) => ({
                ...prev,
                gallery: [...(Array.isArray(prev.gallery) ? prev.gallery : []), ...galleryPreviews],
            });

            if (isEditMode && selectedProject) {
                setSelectedProject(updateState);
            } else {
                setNewProject(updateState);
            }
        }
    };

    const handleBackgroundUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            setSelectedImage(file);

            if (isEditMode && selectedProject) {
                setSelectedProject(prev => prev ? {
                    ...prev,
                    background_image: URL.createObjectURL(file),
                } : null);
            }
        }
    };

    const handleTierChange = (index: number, field: keyof Tier, value: string) => {
        const updateTiers = (tiers: Tier[]) => {
            const updatedTiers = [...tiers];
            updatedTiers[index] = { ...updatedTiers[index], [field]: value };
            return updatedTiers;
        };

        if (isEditMode && selectedProject) {
            setSelectedProject(prev => prev ? {
                ...prev,
                tiers: updateTiers(prev.tiers),
            } : null);
        } else {
            setNewProject(prev => ({
                ...prev,
                tiers: updateTiers(prev.tiers),
            }));
        }
    };

    const handleMilestoneChange = (index: number, field: string, value: string) => {
        const updateMilestones = (milestones: any[]) => {
            const updatedMilestones = [...milestones];
            updatedMilestones[index] = { ...updatedMilestones[index], [field]: value };
            return updatedMilestones;
        };

        if (isEditMode && selectedProject) {
            setSelectedProject(prev => prev ? {
                ...prev,
                milestones: updateMilestones(prev.milestones),
            } : null);
        } else {
            setNewProject(prev => ({
                ...prev,
                milestones: updateMilestones(prev.milestones),
            }));
        }
    };

    const handleAddTier = () => {
        const newTier: Tier = {
            name: '',
            description: '',
            amount: '',
            reward: '',
            available_quantity: '0',
        };

        if (isEditMode && selectedProject) {
            setSelectedProject(prev => prev ? {
                ...prev,
                tiers: [...prev.tiers, newTier],
            } : null);
        } else {
            setNewProject(prev => ({
                ...prev,
                tiers: [...prev.tiers, newTier],
            }));
        }
    };

    const handleAddMilestone = () => {
        const newMilestone = {
            title: '',
            description: '',
            target_date: '',
            status: 'pending'
        };

        if (isEditMode && selectedProject) {
            setSelectedProject(prev => prev ? {
                ...prev,
                milestones: [...prev.milestones, newMilestone],
            } : null);
        } else {
            setNewProject(prev => ({
                ...prev,
                milestones: [...prev.milestones, newMilestone],
            }));
        }
    };

    const handleRemoveTier = (index: number) => {
        if (isEditMode && selectedProject) {
            setSelectedProject(prev => prev ? {
                ...prev,
                tiers: prev.tiers.filter((_, i) => i !== index),
            } : null);
        } else {
            setNewProject(prev => ({
                ...prev,
                tiers: prev.tiers.filter((_, i) => i !== index),
            }));
        }
    };

    const handleRemoveMilestone = (index: number) => {
        if (isEditMode && selectedProject) {
            setSelectedProject(prev => prev ? {
                ...prev,
                milestones: prev.milestones.filter((_, i) => i !== index),
            } : null);
        } else {
            setNewProject(prev => ({
                ...prev,
                milestones: prev.milestones.filter((_, i) => i !== index),
            }));
        }
    };

    const handleCreateProject = async () => {
        try {
            let backgroundImageUrl = '';
            const galleryUrls: string[] = [];

            if (selectedImage) {
                const formData = new FormData();
                formData.append('file', selectedImage);
                const uploadResponse = await fetch(`${API_BASE_URL}/projects/uploadFile`, {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
                    },
                    body: formData,
                });

                if (!uploadResponse.ok) {
                    throw new Error('Image upload failed');
                }

                const data = await uploadResponse.json();
                backgroundImageUrl = data.fileUrl;
            }

            for (const file of galleryFiles) {
                const formData = new FormData();
                formData.append('file', file);
                const uploadResponse = await fetch(`${API_BASE_URL}/projects/uploadFile`, {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
                    },
                    body: formData,
                });

                if (!uploadResponse.ok) {
                    throw new Error('Gallery image upload failed');
                }

                const data = await uploadResponse.json();
                galleryUrls.push(data.fileUrl);
            }

            const projectData = {
                ...newProject,
                background_image: backgroundImageUrl,
                gallery: galleryUrls,
            };

            const response = await createProject(formatProjectData(projectData));

            if (response.error) {
                throw new Error(response.error);
            }

            const updatedProjects = await getProjects();
            setProjects(updatedProjects);
            setShowCreateModal(false);
            setNewProject(emptyProject);
        } catch (error) {
            console.error('Erreur lors de la création du projet:', error);
            alert(`Erreur : ${error.message}`);
        }
    };

    const handleUpdateProject = async () => {
        try {
            if (!selectedProject) return;

            const response = await updateProject(selectedProject.id!, formatProjectData(selectedProject));

            if (response.error) {
                throw new Error(response.error);
            }

            const updatedProjects = await getProjects();
            setProjects(updatedProjects);
            setShowDetailsModal(false);
            setIsEditMode(false);
        } catch (error) {
            console.error('Erreur lors de la mise à jour du projet:', error);
            alert(`Erreur : ${error.message}`);
        }
    };

// Add these handlers
    const handleTeamMemberChange = (index: number, field: keyof TeamMember, value: string) => {
        if (isEditMode && selectedProject) {
            setSelectedProject(prev => prev ? {
                ...prev,
                teamMembers: prev.teamMembers.map((member, i) =>
                    i === index ? { ...member, [field]: value } : member
                ),
            } : null);
        } else {
            setNewProject(prev => ({
                ...prev,
                teamMembers: prev.teamMembers.map((member, i) =>
                    i === index ? { ...member, [field]: value } : member
                ),
            }));
        }
    };

    const handleAddTeamMember = () => {
        const newMember: TeamMember = {
            name: '',
            role: '',
            bio: ''
        };

        if (isEditMode && selectedProject) {
            setSelectedProject(prev => prev ? {
                ...prev,
                teamMembers: [...prev.teamMembers, newMember],
            } : null);
        } else {
            setNewProject(prev => ({
                ...prev,
                teamMembers: [...prev.teamMembers, newMember],
            }));
        }
    };

    const handleRemoveTeamMember = (index: number) => {
        if (isEditMode && selectedProject) {
            setSelectedProject(prev => prev ? {
                ...prev,
                teamMembers: prev.teamMembers.filter((_, i) => i !== index),
            } : null);
        } else {
            setNewProject(prev => ({
                ...prev,
                teamMembers: prev.teamMembers.filter((_, i) => i !== index),
            }));
        }
    };


    return (
        <Layout>
            <Container fluid className="px-4 mb-4 crowdfunding-bg">
                <Button
                    variant="primary"
                    className="mb-3"
                    onClick={() => setShowCreateModal(true)}
                >
                    Créer un nouveau projet
                </Button>

                <Row>
                    {projects.map((project) => (
                        <ProjectCard
                            key={project.id}
                            project={project}
                            onShowDetails={handleShowDetails}
                        />
                    ))}
                </Row>

                <CreateProjectModal
                    show={showCreateModal}
                    onHide={() => setShowCreateModal(false)}
                    project={newProject}
                    onCreateProject={handleCreateProject}
                    onInputChange={handleInputChange}
                    onGalleryUpload={handleGalleryUpload}
                    onBackgroundUpload={handleBackgroundUpload}
                    onTierChange={handleTierChange}
                    onAddTier={handleAddTier}
                    onRemoveTier={handleRemoveTier}
                    onMilestoneChange={handleMilestoneChange}
                    onAddMilestone={handleAddMilestone}
                    onRemoveMilestone={handleRemoveMilestone}
                    onTeamMemberChange={handleTeamMemberChange}
                    onAddTeamMember={handleAddTeamMember}
                    onRemoveTeamMember={handleRemoveTeamMember}
                />

                <ProjectDetailsModal
                    show={showDetailsModal}
                    onHide={() => {
                        setShowDetailsModal(false);
                        setIsEditMode(false);
                    }}
                    project={selectedProject}
                    isEditMode={isEditMode}
                    onToggleEditMode={() => setIsEditMode(!isEditMode)}
                    onUpdateProject={handleUpdateProject}
                    onInputChange={handleInputChange}
                    onGalleryUpload={handleGalleryUpload}
                    onBackgroundUpload={handleBackgroundUpload}
                    onTierChange={handleTierChange}
                    onAddTier={handleAddTier}
                    onRemoveTier={handleRemoveTier}
                    onMilestoneChange={handleMilestoneChange}
                    onAddMilestone={handleAddMilestone}
                    onRemoveMilestone={handleRemoveMilestone}
                    onTeamMemberChange={handleTeamMemberChange}
                    onAddTeamMember={handleAddTeamMember}
                    onRemoveTeamMember={handleRemoveTeamMember}
                />
            </Container>
        </Layout>
    );
};

export default Projects;
