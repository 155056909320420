import React, {useEffect, useState} from 'react';
import {useParams, useLocation} from 'react-router-dom';
import {Container, Row, Col, Card, Button, ProgressBar, Form, Spinner, Carousel, Modal} from 'react-bootstrap';
import {FacebookLogo, TwitterLogo, LinkedinLogo} from 'phosphor-react';
import {loadStripe} from '@stripe/stripe-js';
import DonorsList from '../donors';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {
    ResponsiveContainer,
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend
} from 'recharts';
import './Crowdfunding.css';

import {HeroSection} from "./sections/HeroSection";
import {ProgressSection} from "./sections/ProgressSection";
import {MilestonesSection} from "./sections/MilestonesSection";
import PartnersSection from "./sections/PartnersSection";
import {GallerySection} from "./sections/GallerySection";
import DonationSection from './sections/DonationSection';
import {WalletHistorySection} from "./sections/WalletHistorySection";
import DonorList from './sections/DonorList';
import TermsAndConditions from './sections/TermsAndConditions';
import Team from './sections/Team';
import Contact from './sections/Contact';
import DisplayURL from './sections/siteUrl';


const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8084';

dayjs.extend(customParseFormat);
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '');


interface TeamMember {
    name: string;
    role: string;
    photoUrl: string;
}


interface WalletHistoryItem {
    date: string;
    amount: number;
    type: string;
}

interface ProjectData {
    id: number;
    title: string;
    description: string;
    target_amount: number;
    raised_funds: number;
    end_date: string;
    status: string;
    background_image: string;
    video?: string;
    gallery?: string[];
    partners?: Partner[];
    tiers?: Tier[];
    wallet_history: WalletHistoryItem[];
    backers?: number;
    creator_id: number;
    category: string;
    start_date: string;
    detailed_description: string;
    minimum_contribution: number;
    website_url: string;
    approval_status: string;
    comments?: any[];
    contributor_count: number;
    updates?: any[];
    tags?: string[];
    priority_level?: string;
    milestones?: any[];
    team_members: TeamMember[];
    contact_information: string;
}

interface Tier {
    id: string;       // Ajout de l'ID
    tier_title: string;
    tier_description: string;
    minimum_amount: number;
    quantity_available: number;
    estimated_delivery_date: string;
    reward_type: string;
    tier_status: string;
    is_exclusive: boolean;
    current_contributors: number;
    shipping_cost?: number;
    customization_options?: {
        tshirt_size?: string[];
        color?: string[];
    };
    amount: number;
    reward: string;
    description: string;
    available_quantity: number;
    name?: string;
}

interface Partner {
    name: string;
    logo: string;
    url: string;
}

interface Donor {
    id: number;
    name: string;
    amount: number;
    date: string;
    isAnonymous: boolean;
}


export const ProjectCrowdfunding: React.FC = () => {
    const {id} = useParams<{ id: string }>();
    const location = useLocation();
    const [projectData, setProjectData] = useState<ProjectData | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [donorName, setDonorName] = useState<string>('');
    const [isAnonymous, setIsAnonymous] = useState<boolean>(false);
    const [selectedAmount, setSelectedAmount] = useState<number | null>(null);
    const [selectedTier, setSelectedTier] = useState<Tier | null>(null);
    const [canSelectAmount, setCanSelectAmount] = useState<boolean>(false);
    const [percentageFunded, setPercentageFunded] = useState<number>(0);
    const [shareUrl, setShareUrl] = useState<string>('');
    const [shareText, setShareText] = useState<string>('');
    const [showModal, setShowModal] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0); // Track the active image in the carousel
    const [showModalInfos, setShowModalInfos] = useState(false);
    const [daysRemaining, setDaysRemaining] = useState<number | null>(null);
    const [donors, setDonors] = useState<Donor[]>([]);

    const handleShowModal = (index: number) => {
        setShowModal(true);
    };

    const handleShowModalInfos = (index: number) => {
        setActiveIndex(index);
        setShowModalInfos(true);
    };

    useEffect(() => {
        const currentUrl = window.location.href;
        setShareUrl(currentUrl);
        setShareText(`Soutenez le projet ${projectData?.title}`);
    }, [location, projectData]);

    // Fetch project data from the backend
    useEffect(() => {
        const fetchProjectData = async () => {
            if (!id) {
                setError('Project ID is not provided.');
                return;
            }

            try {
                const response = await fetch(`${API_BASE_URL}/projects/${id}`);

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const data = await response.json();
                console.log("Data fetched:", data);

                // Parse wallet_history from string to JSON if it exists
                if (data.project.wallet_history) {
                    data.project.wallet_history = JSON.parse(data.project.wallet_history);
                }

                setProjectData(data.project);

                // Calculate percentage funded
                const percentage = ((data.project.raised_funds / data.project.target_amount) * 100);
                setPercentageFunded(Number(percentage));

            } catch (error: any) {
                console.error('Error in fetchProjectData:', error);
                setError('Échec de la récupération des données. Veuillez réessayer plus tard.');
            }
        };

        fetchProjectData();
    }, [id]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch donors
                const donorsResponse = await fetch(`${API_BASE_URL}/donors?typeId=project&userId=${id}`);
                if (!donorsResponse.ok) {
                    throw new Error('Failed to fetch donors data');
                }
                const donorsData = await donorsResponse.json();
                setDonors(donorsData); // Assurez-vous que `setDonors` est défini dans votre composant

                console.log("Donors data:", donorsData);

            } catch (error: any) {
                console.error('Error fetching donors data:', error);
                setError(error.message); // Assurez-vous que `setError` est défini pour gérer les erreurs
            }
        };

        if (id) {
            fetchData(); // Appeler la fonction fetchData si `id` est défini
        }
    }, [id]);


    const handleDonorNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDonorName(e.target.value);
        setCanSelectAmount(e.target.value.trim() !== '' || isAnonymous);
    };

    const handleAnonymousChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsAnonymous(e.target.checked);
        setCanSelectAmount(e.target.checked || donorName.trim() !== '');
    };

    const handleTierSelect = (tier: Tier) => {
        if (!tier.amount && !tier.minimum_amount) {
            console.error('Tier sans montant défini:', tier);
            setError('Ce niveau de récompense n\'a pas de montant défini');
            return;
        }

        setSelectedTier(tier);
        setSelectedAmount(tier.amount || tier.minimum_amount);
        setCanSelectAmount(true);
    };

    // Function to extract YouTube video ID and create embed URL
    const getYouTubeEmbedUrl = (url: string | undefined) => {
        if (!url) return null;
        const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
        const match = url.match(regex);
        return match ? `https://www.youtube.com/embed/${match[1]}` : null;
    };

    const CustomTooltip: React.FC<{ active?: boolean; payload?: any }> = ({active, payload}) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip"
                     style={{backgroundColor: '#333', padding: '10px', borderRadius: '5px', color: '#fff'}}>
                    <p className="label">{`Solde : ${payload[0].value} CHF`}</p>
                </div>
            );
        }
        return null;
    };

    // Fonction pour calculer les jours restants
    const calculateDaysRemaining = (endDate: string): number => {
        const today = dayjs();
        const end = dayjs(endDate);
        const diff = end.diff(today, 'day'); // Différence en jours
        return diff > 0 ? diff : 0; // Retourne 0 si la date est passée
    };


    if (error) {
        return (
            <Container fluid className="px-4 mt-4">
                <Row>
                    <Col xl={8} className="offset-xl-2">
                        <Card className="shadow-sm mb-4 text-center">
                            <Card.Body>
                                <Card.Title className="text-danger">Erreur</Card.Title>
                                <Card.Text>{error}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }

    if (!projectData) {
        return (
            <Container fluid className="px-4 mt-4">
                <Row>
                    <Col xl={8} className="offset-xl-2">
                        <Card className="shadow-sm mb-4 text-center">
                            <Card.Body>
                                <div className="d-flex flex-column align-items-center">
                                    <Card.Title className="text-info">Chargement...</Card.Title>
                                    <Card.Text>
                                        Veuillez patienter pendant que nous chargeons les données.
                                    </Card.Text>
                                    <Spinner animation="border" variant="primary"/>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }

    const getDaysRemainingColor = (days: number | null) => {
        if (days === null) return '#fff'; // Default color if value is null
        if (days > 10) return '#fff';
        if (days > 5) return '#ffcc00';
        return '#FF9400FF'; // 5 days or less remaining
    };


    return (
        <div className="bg-container-crowdfunding" style={{backgroundImage: `url(${projectData.background_image})`}}>
            <Container className="pt-4 pb-5">
                {/* Section 1: Project Title and Visuals */}
                <Row className="justify-content-center mb-5">
                    <Col xl={12}>
                        <Card className="mb-4 crowdfunding-card shadow-sm">
                            <Card.Body>


                                <HeroSection
                                    title={projectData.title}
                                    description={projectData.description}
                                    video={projectData.video} // Peut être `undefined`
                                    targetAmount={projectData.target_amount}
                                    raisedFunds={projectData.raised_funds}
                                    endDate={projectData.end_date}
                                    contributorCount={projectData.contributor_count}
                                />

                                <ProgressSection
                                    targetAmount={projectData.target_amount}
                                    raisedFunds={projectData.raised_funds}
                                    endDate={projectData.end_date}
                                    contributorCount={projectData.contributor_count}
                                />


                                <PartnersSection partners={projectData.partners || []}/>

                                <GallerySection gallery={projectData.gallery || []}/>


                                <DonationSection
                                    minimumAmount={10}
                                    tiers={projectData?.tiers || []} // Utilisation d'une valeur par défaut si tiers est null
                                    projectData={projectData} // Assurez-vous que projectData n'est pas null
                                    onDonate={(donationData) => {
                                        console.log('Données de don:', donationData);
                                    }}
                                />

                                <div style={{
                                    marginBottom: 32,
                                    padding: 10,
                                    borderRadius: 10,
                                    backgroundColor: 'rgba(82,87,255,0.11)',
                                    border: '2px solid #5257ff'
                                }}>
                                    <WalletHistorySection history={projectData.wallet_history || []}/>

                                    <DonorList donors={donors}/>
                                </div>

                                    <Team members={projectData.team_members}/>

                                    <Contact/>

                                    <DisplayURL url={projectData.website_url}/>


                                <div style={{
                                    marginBottom: 32,
                                    padding: 10,
                                    borderRadius: 10,
                                    backgroundColor: 'rgba(82,87,255,0.11)',
                                    border: '2px solid #5257ff'
                                }}>
                                    <TermsAndConditions/>
                                </div>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ProjectCrowdfunding;
