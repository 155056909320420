import React, {useEffect, useState} from 'react';
import {useParams, useLocation} from 'react-router-dom';
import {Container, Row, Col, Card, Button, ProgressBar, Form, Spinner, Modal} from 'react-bootstrap';
import { FacebookLogo, TwitterLogo, LinkedinLogo } from 'phosphor-react';
import {loadStripe} from '@stripe/stripe-js';
import DonorsList from '../donors';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {
    ResponsiveContainer,
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend
} from 'recharts';
import './Crowdfunding.css';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8084';

dayjs.extend(customParseFormat);
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '');

interface WalletHistoryItem {
    date: string;
    amount: number;
    type: string;
}

interface UserData {
    id: number;
    email: string;
    first_name: string;
    profile_picture: string | null;
    usable_funds: string;
    wallet_history: WalletHistoryItem[];
    targetAmount: string;
    daysLeft: number;
    backers: number;
    cursus: string;
    reserved_percentage: string;
    donationAmounts: string;
}

interface CourseData {
    CourseID: number;
    CategoryID: string;
    Title: string;
    Status: string;
    SmallDescription: string;
    Description: string;
    Duration: number;
    Price: number;
    BackgroundImage: string;
    Certification: string;
    Agenda: string;
    CourseVideoUrl: string;
    Slug: string;
}
const PAGE_VIEW_KEY = 'page_view_timestamp';



export const Crowdfunding: React.FC = () => {

    const { slug, id } = useParams<{ slug: string; id: string }>();
    const location = useLocation(); // Récupérer l'URL actuelle

    const [userData, setUserData] = useState<UserData | null>(null);
    const [courseData, setCourseData] = useState<CourseData | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [donorName, setDonorName] = useState<string>('');
    const [isAnonymous, setIsAnonymous] = useState<boolean>(false);
    const [selectedAmount, setSelectedAmount] = useState<number | null>(null);
    const [canSelectAmount, setCanSelectAmount] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [transactionDetails, setTransactionDetails] = useState<any>(null);
    const [daysRemaining, setDaysRemaining] = useState<number | null>(null);
    const [calculatedReservedFunds, setCalculatedReservedFunds] = useState<number>(0);
    const [percentageFunded, setPercentageFunded] = useState<number>(0);
    const [showPageModal, setShowPageModal] = useState(false);
    const [modalContent, setModalContent] = useState<string>('');
    const [modalTitle, setModalTitle] = useState<string>('');
    const [donationAmounts, setDonationAmounts] = useState<number[]>([]); // Initialize as an array of numbers

    const [shareUrl, setShareUrl] = useState<string>('');
    const [shareText, setShareText] = useState<string>('');

    useEffect(() => {
        // Construct the full share URL dynamically
        const currentUrl = `${window.location.origin}${location.pathname}`;
        const pathSegments = window.location.pathname.split("/"); // Divise l'URL en segments
        const userType = pathSegments[1]; // Récupère le troisième segment (index 2)
        setShareUrl(userType);

        // Optionally, set the share text (customized for social sharing)
        setShareText(`Découvrez la campagne de ${slug} et contribuez à son succès ici : ${currentUrl}`);

        console.log('Share URL:', currentUrl);
    }, [slug, id, location.pathname]);


    const handlePageModal = (title: string, content: string) => {
        setModalTitle(title);
        setModalContent(content);
        setShowPageModal(true);
    };

    const CustomTooltip: React.FC<{ active?: boolean; payload?: any }> = ({active, payload}) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip"
                     style={{backgroundColor: '#333', padding: '10px', borderRadius: '5px', color: '#fff'}}>
                    <p className="label">{`Solde : ${payload[0].value} CHF`}</p>
                </div>
            );
        }
        return null;
    };

    useEffect(() => {

        const checkPageView = () => {
            const lastView = localStorage.getItem(PAGE_VIEW_KEY);
            const now = new Date();

            if (lastView) {
                const lastViewDate = new Date(lastView);
                const diffInHours = (now.getTime() - lastViewDate.getTime()) / (1000 * 60 * 60); // Convert to number

                // Si la dernière visite date de plus de 24 heures, mettre à jour les vues
                if (diffInHours > 24) {
                    updatePageViews();
                    localStorage.setItem(PAGE_VIEW_KEY, now.toISOString());
                }
            } else {
                // Première visite, stocker la date actuelle
                updatePageViews();
                localStorage.setItem(PAGE_VIEW_KEY, now.toISOString());
            }
        };


        const updatePageViews = async () => {
            console.log('views');
            try {
                await fetch(`${API_BASE_URL}/users/views/${id}`, {
                    method: 'POST',
                });
                console.log('Page views updated successfully.');
            } catch (error) {
                console.error('Failed to update page views:', error);
            }
        };

        checkPageView();
    }, [id]);

    // Récupérer les données depuis le backend
    useEffect(() => {
        const fetchDonationAmounts = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}/parts`);  // Ensure you use the correct API endpoint
                const data = await response.json();
                {/* console.log('Parts data:', data); */
                }

                // Assuming donationAmounts is in the first element of the array `data`
                let donationAmountsArray = [];
                if (data.length > 0 && typeof data[0].donationAmounts === 'string') {
                    // Parse the string into an array
                    donationAmountsArray = JSON.parse(data[0].donationAmounts);
                    {/* console.log('donationAmountsArray:', donationAmountsArray); */
                    }
                }

                // Set donation amounts in state
                setDonationAmounts(donationAmountsArray);
            } catch (error) {
                console.error('Error fetching parts data:', error);
            }
        };

        fetchDonationAmounts();
    }, []);


    useEffect(() => {
        const fetchData = async () => {
            if (!id) {
                setError('User ID is not provided.');
                console.error('Fetch Error: Missing User ID');
                return;
            }

            try {
                console.log(`Fetching user data from: ${API_BASE_URL}/api/v1/slug/${id}`);
                const userResponse = await fetch(`${API_BASE_URL}/api/v1/slug/${id}`);

                if (!userResponse.ok) {
                    const errorText = await userResponse.text();
                    throw new Error(`Failed to fetch user data. Status: ${userResponse.status}. Response: ${errorText}`);
                }

                const userData: UserData = await userResponse.json();
                console.log('User data fetched:', userData);
                setUserData(userData);
                setShareText(`École 3èmelieu à Genève : Investissez dans l'avenir de ${userData.first_name}`);

                if (userData.cursus) {
                    console.log(`Fetching course data from: ${API_BASE_URL}/api/v1/courses/${userData.cursus}`);
                    const courseResponse = await fetch(`${API_BASE_URL}/api/v1/courses/${userData.cursus}`);

                    if (!courseResponse.ok) {
                        const errorText = await courseResponse.text();
                        throw new Error(`Failed to fetch course data. Status: ${courseResponse.status}. Response: ${errorText}`);
                    }

                    const courseData = await courseResponse.json();
                    console.log('Course data fetched:', courseData);
                    setCourseData(courseData.course);

                    console.log(`Fetching donors data from: ${API_BASE_URL}/donors?userId=${userData.id}`);
                    const donorsResponse = await fetch(`${API_BASE_URL}/donors?typeId=user&userId=${userData.id}`);


                    if (!donorsResponse.ok) {
                        const errorText = await donorsResponse.text();
                        throw new Error(`Failed to fetch donors data. Status: ${donorsResponse.status}. Response: ${errorText}`);
                    }

                    const donorsData = await donorsResponse.json();
                    console.log('Donors data fetched:', donorsData);

                    const reservedFundsCalculated = donorsData
                        .filter((donor: any) => donor.type === 'scholarship')
                        .reduce((acc: number, donor: any) => acc + donor.value, 0);

                    setCalculatedReservedFunds(reservedFundsCalculated);
                    setUserData(prevState => ({
                        ...prevState,
                        backers: donorsData.length,
                    }));

                    if (courseData?.course?.Agenda) {
                        try {
                            const agendaData = typeof courseData.course.Agenda === 'string'
                                ? JSON.parse(courseData.course.Agenda)
                                : courseData.course.Agenda;

                            if (agendaData?.length > 0) {
                                const startDateString = agendaData[0].StartDate;
                                const startDate = dayjs(startDateString, 'DD.MM.YYYY');
                                const today = dayjs();

                                if (startDate.isValid()) {
                                    const diffDays = startDate.diff(today, 'day');
                                    setDaysRemaining(diffDays);
                                }
                            }
                        } catch (error) {
                            console.error('Error parsing course agenda:', error);
                        }
                    }
                } else {
                    setError('Cursus not found.');
                    console.warn('Warning: User does not have a cursus.');
                }
            } catch (error: any) {
                console.error('Fetch Error:', error.message);
                setError(`Échec de la récupération des données: ${error.message}`);
            }
        };

        fetchData();

        const urlParams = new URLSearchParams(location.search);
        const sessionId = urlParams.get('session_id');
        if (sessionId) {
            fetchTransactionDetails(sessionId);
        }
    }, [id, location]);


    useEffect(() => {
        if (userData && userData.targetAmount && userData.reserved_percentage) {
            const targetAmount = Number(userData.targetAmount);
            const reservedPercentage = Number(userData.reserved_percentage);

            if (isNaN(targetAmount) || isNaN(reservedPercentage)) {
                console.error('Invalid number values for targetAmount or reserved_percentage');
                return;
            }

            // Calculate reserved funds
            let reservedFundsCalculated = targetAmount / 100 * reservedPercentage;
            console.log('Initial reserved funds:', reservedFundsCalculated);

            // Check courseData Certification
            if (courseData && courseData.Certification) {
                const certification = courseData.Certification.trim(); // Trim any extra spaces
                console.log('Trimmed courseData.Certification:', certification);

                if (certification === 'Diploma') {
                    reservedFundsCalculated /= 2;
                } else if (certification === 'Bachelor') {
                    console.log('Bachelor detected, dividing reservedFunds by 3');
                    reservedFundsCalculated /= 3;
                    console.log('Updated reserved funds after Bachelor:', reservedFundsCalculated);
                }
            }

            console.log('Calculated reserved funds:', reservedFundsCalculated);

            // Total funds
            const usableFunds = Number(userData.usable_funds);
            const totalFunds = reservedFundsCalculated + usableFunds;
            console.log('Total Funds:', totalFunds);

            const calculatedPercentage = ((totalFunds / targetAmount) * 100).toFixed(2);
            console.log('Calculated Percentage:', calculatedPercentage);

            setCalculatedReservedFunds(reservedFundsCalculated);
            setPercentageFunded(Number(calculatedPercentage));
        }
    }, [userData, courseData]);

    const fetchTransactionDetails = async (sessionId: string) => {
        try {
            const response = await fetch(`${API_BASE_URL}/get-transaction?session_id=${sessionId}`);
            if (!response.ok) {
                throw new Error(`Failed to fetch transaction details: ${response.statusText}`);
            }
            const data = await response.json();
            setTransactionDetails(data);
            setShowModal(true);
        } catch (error) {
            console.error('Error fetching transaction details:', error);
        }
    };

    const handlePayment = async () => {
        if (selectedAmount === null || (!isAnonymous && donorName.trim() === '')) {
            setError('Veuillez sélectionner un montant et entrer votre nom ou choisir de donner anonymement.');
            return;
        }

        try {
            const requestBody = {
                amount: selectedAmount * 100,
                userId: userData?.id,
                donorName: isAnonymous ? 'Anonyme' : donorName,
                type: 'crowdfunding',
            };

            const response = await fetch(`${API_BASE_URL}/create-checkout-session`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });

            if (!response.ok) {
                throw new Error(`Network response was not ok: ${response.statusText}`);
            }

            const session = await response.json();
            const stripe = await stripePromise;

            const {error} = await stripe?.redirectToCheckout({
                sessionId: session.id,
            });

            if (error) {
                setError(error.message);
            }
        } catch (error) {
            setError('Failed to create checkout session: ' + error.message);
        }
    };

    const handleDonorNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDonorName(e.target.value);
        setCanSelectAmount(e.target.value.trim() !== '' || isAnonymous);
    };

    const handleAnonymousChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsAnonymous(e.target.checked);
        setCanSelectAmount(e.target.checked || donorName.trim() !== '');
    };


    if (error) {
        return (
            <Container fluid className="px-4 mt-4">
                <Row>
                    <Col xl={8} className="offset-xl-2">
                        <Card className="shadow-sm mb-4">
                            <Card.Body>
                                <Card.Title className="text-danger">Erreur</Card.Title>
                                <Card.Text>{error}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }

    if (!userData || !courseData) {
        return (
            <Container fluid className="px-4 mt-4">
                <Row>
                    <Col xl={8} className="offset-xl-2">
                        <Card className="shadow-sm mb-4">
                            <Card.Body>
                                <div style={{
                                    margin: 32,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <Card.Title className="text-info">Chargement...</Card.Title>
                                    <Card.Text>
                                        Veuillez patienter pendant que nous chargeons les données.
                                    </Card.Text>
                                    <div style={{margin: 10}}><Spinner animation="border" variant="primary"/></div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

        );
    }



    const getDaysRemainingColor = (days: number | null) => {
        if (days === null) return '#fff'; // Default color if value is null
        if (days > 10) return '#fff';
        if (days > 5) return '#ffcc00';
        return '#FF9400FF'; // 5 days or less remaining
    };


    return (
        <div className="bg-container-crowdfunding" style={{
            backgroundImage: `linear-gradient(rgba(35, 40, 85, 0.0), rgba(35, 40, 85, 0.9)), url(https://3emelieu.ch${courseData?.BackgroundImage || ''})`,
            paddingBottom: '20px'
        }}>
            <div style={{backdropFilter: 'blur(16px)'}}>
                <Container style={{padding: 0, overflowX: 'hidden', paddingTop: 10}}>
                    <Row className="justify-content-center mb-5">
                        <Col xl={10}>
                            <Card className="mb-4 crowdfunding-card">
                                <Card.Body>
                                    <div className="text-center mb-4">
                                        <img src="/3emelieu_logo_and_txt_Blanc.png"
                                             alt="3èmelieu Centre de formation à Genève"
                                             className="img-fluid w-75 my-4"
                                        style={{ maxWidth: 540 }}/>
                                    </div>
                                    <div className="mb-4">
                                        <Row className="text-center mb-4">
                                            <Col xs={12} md={4}>
                                                <img
                                                    src={"../../3emelieu.png"}
                                                    alt={userData.first_name}
                                                    className="img-fluid rounded-circle mb-3"
                                                    style={{
                                                        width: '120px',
                                                        height: '120px',
                                                        objectFit: 'cover',
                                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
                                                    }}
                                                />
                                            </Col>
                                            <Col xs={12} md={8} className="d-flex flex-column justify-content-center">
                                                <p style={{fontSize: '1.2em', lineHeight: '1.6'}}>
                                                    3èmelieu SA est une école située à Carouge, Genève, qui propose des
                                                    cursus
                                                    académiques et des formations courtes dans le domaine des
                                                    technologies
                                                    numériques (Web, Web3, Webmarketing, Game Art, Graphic Design).
                                                </p>
                                                <div className="d-flex justify-content-center">
                                                    <a href="https://3emelieu.ch" target="_blank" rel="noreferrer">
                                                        <Button variant="primary" className="rounded-pill px-4 py-2">Plus
                                                            d'informations sur 3emelieu.ch</Button>
                                                    </a>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <Card.Body>
                                        <div style={{margin: 16, textAlign: 'center'}}>
                                            <h2>
                                                Investissez dans l'avenir de <span
                                                style={{color: '#00ff8c'}}>{userData.first_name}</span>
                                            </h2>
                                        </div>
                                        <div style={{
                                            marginBottom: 32,
                                            padding: 10,
                                            borderRadius: 10,
                                            backgroundColor: 'rgba(82,87,255,0.11)',
                                            border: '2px solid #5257ff'
                                        }}>
                                            <Row className="mt-4">
                                                <Col md={12}>
                                                    <h5 style={{textAlign: 'center'}}>Formation</h5>
                                                    <h2 style={{textAlign: 'center'}}>{courseData?.Title}</h2>
                                                    <div className="custom-progress-bar my-3">
                                                        <ProgressBar
                                                            now={percentageFunded} // Use the updated percentage funded
                                                            label={`${Math.floor(percentageFunded)}%`}
                                                            className="bg-gradient-success my-3"
                                                        />
                                                    </div>
                                                    <p className="text-center"
                                                       style={{fontSize: '2.0em', fontWeight: 'bold'}}>
                                                        <strong>{(Math.floor((parseFloat(userData.targetAmount) * percentageFunded) / 100 ))}</strong> CHF
                                                        collectés sur {parseFloat(userData.targetAmount)} CHF
                                                    </p>

                                                </Col>
                                                <Col md={12} className="text-center mt-4">
                                                    <div className="d-flex justify-content-around">
                                                      <span className="infobule-crowdfunding"
                                                            style={{fontSize: '1.2em'}}>
                                                          {Math.floor(percentageFunded)}% Financé
                                                      </span>
                                                        <span
                                                            className="infobule-crowdfunding"
                                                            style={{
                                                                fontSize: '1.2em',
                                                                color: getDaysRemainingColor(daysRemaining)
                                                            }}>{daysRemaining} Jours restants</span>
                                                        <span className="infobule-crowdfunding"
                                                              style={{fontSize: '1.2em'}}>{userData.backers} Contributeurs</span>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        <Row className="text-center mb-4">
                                            <Col xs={12} md={4}>
                                                <img
                                                    src={userData?.profile_picture || "../../void2.png"}
                                                    alt={userData.first_name}
                                                    className="img-fluid rounded-circle mb-3"
                                                    style={{
                                                        width: '120px',
                                                        height: '120px',
                                                        objectFit: 'cover',
                                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
                                                    }}
                                                />
                                                <h3 className="text-primary">{userData.first_name}</h3>
                                            </Col>
                                            <Col xs={12} md={8} className="d-flex flex-column justify-content-center">

                                                <p style={{fontSize: '1.1em', lineHeight: '1.6'}}>
                                                    Aidez {userData.first_name} à réaliser ses ambitions
                                                    professionnelles en
                                                    contribuant à sa campagne de financement pour sa formation.
                                                </p>
                                                <div className="alert alert-info" role="alert">
                                                    Les fonds seront remboursés si l'objectif de financement n'est pas
                                                    atteint dans <div><strong> {daysRemaining} </strong> jours.</div>
                                                </div>
                                                <Form>
                                                    <Form.Group className="mb-3" controlId="formDonorName">
                                                        <Form.Label>Nom du contributeur</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Entrez votre nom"
                                                            value={donorName}
                                                            onChange={handleDonorNameChange}
                                                            disabled={isAnonymous}
                                                            style={{borderRadius: '10px', padding: '0.75rem'}}
                                                        />
                                                    </Form.Group>
                                                    ou
                                                    <div style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-around',
                                                        alignItems: 'center',
                                                        backgroundColor: 'rgb(22, 24, 49)',
                                                        margin: '10px 10px 32px 10px',
                                                        borderRadius: '10px',
                                                    }}>
                                                        <div style={{marginTop: 0}}>
                                                            <Form.Group className="mb-3" controlId="formAnonymous">
                                                                <Form.Group controlId="formAnonymous"
                                                                            className="mb-3 custom-checkbox">
                                                                    <Form.Check
                                                                        type="checkbox"
                                                                        checked={isAnonymous}
                                                                        onChange={handleAnonymousChange}
                                                                        className="custom-form-check-input"
                                                                    />
                                                                </Form.Group>
                                                            </Form.Group>
                                                        </div>
                                                        <div style={{fontSize: '1.2em', marginBottom: 10}}>Donner
                                                            anonymement
                                                        </div>
                                                    </div>

                                                </Form>
                                                <Card className="mb-3" style={{borderRadius: '10px'}}>
                                                    <Card.Body>
                                                        <Card.Title>Sélectionnez un montant de don</Card.Title>
                                                        <div className="d-flex flex-wrap justify-content-around">
                                                            {Array.isArray(donationAmounts) && donationAmounts.map((amount) => (
                                                                <Button
                                                                    key={amount}
                                                                    onClick={() => setSelectedAmount(amount)}
                                                                    className={`btn m-2 ${selectedAmount === amount ? 'btn-success' : 'btn-primary'} rounded-pill px-4 py-2`}
                                                                    disabled={!canSelectAmount}
                                                                >
                                                                    {amount} CHF
                                                                </Button>
                                                            ))}
                                                        </div>
                                                    </Card.Body>
                                                </Card>

                                                <div style={{margin: 32}}>
                                                    <Button
                                                        variant="text-white"
                                                        style={{border: '2px solid #ff9400', color: '#fff'}}
                                                        onClick={() => handlePageModal("Conditions d'utilisation", `
    <h3>Conditions d'utilisation</h3>
    <p>Bienvenue sur la plateforme de financement participatif de <strong>3èmelieu SA</strong>, située au <strong>2bis rue Baylon, Genève, Suisse</strong>. En utilisant notre plateforme, vous acceptez les conditions générales décrites ci-dessous. Cette plateforme est spécifiquement destinée à soutenir les étudiants dans le financement de leur formation, ainsi que des projets éducatifs, culturels, d'innovation sociale et d'intégration professionnelle proposés par 3èmelieu SA.</p>

    <h4>1. Objectif de la plateforme</h4>
    <p>La plateforme permet aux étudiants de solliciter des financements pour leurs formations au sein de 3èmelieu SA, ainsi que pour des projets éducatifs, artistiques, culturels, ou sociaux. Les fonds collectés doivent être utilisés exclusivement pour ces objectifs, garantissant ainsi l'impact positif de chaque campagne.</p>

    <h4>2. Comptes utilisateur et sécurité</h4>
    <p>Seuls les étudiants qui souhaitent lancer une campagne de financement doivent créer un compte personnel. Ils sont responsables de la confidentialité de leurs informations de connexion et de toutes les activités effectuées sous leur compte. Nous recommandons vivement de sécuriser vos informations. Les donateurs ne sont pas tenus de créer un compte pour effectuer un don.</p>

    <h4>3. Gestion des fonds et objectifs de campagne</h4>
    <p>Tous les fonds collectés sont directement gérés par <strong>3èmelieu SA</strong>. Les étudiants ne reçoivent pas les fonds eux-mêmes. Si l'objectif de la campagne n'est pas atteint dans le délai imparti, les fonds sont automatiquement remboursés aux donateurs, sauf pour les campagnes lancées directement par <strong>3èmelieu SA</strong> dans le cadre de projets éducatifs ou culturels. Dans ces cas, les fonds seront utilisés pour soutenir les initiatives de l'institution.</p>

    <h4>4. Droits des contributeurs</h4>
    <p>Les contributeurs apportent leur soutien en toute confiance, sachant que les fonds sont sous la gestion directe de <strong>3èmelieu SA</strong>. En cas d'échec d'une campagne à atteindre son objectif dans le délai prévu, les fonds sont intégralement remboursés, sauf pour les campagnes institutionnelles initiées par <strong>3èmelieu SA</strong>. Toutefois, 3èmelieu SA n'est pas responsable des engagements non tenus dans les campagnes menées par des tiers ou des étudiants.</p>

    <h4>5. Propriété intellectuelle</h4>
    <p>Tout le contenu de cette plateforme, y compris les textes, images, vidéos et logos, est protégé et appartient à <strong>3èmelieu SA</strong> ou aux créateurs de projets. Toute utilisation, reproduction ou diffusion non autorisée de ces éléments est strictement interdite sans l'accord préalable de leurs propriétaires.</p>

    <h4>6. Responsabilité et garanties</h4>
    <p>Bien que <strong>3èmelieu SA</strong> s'efforce de garantir une plateforme fiable et de qualité, elle ne peut être tenue responsable du succès ou de l'échec des campagnes, ni des retards ou des modifications dans la réalisation des projets financés. La responsabilité de l'aboutissement des projets incombe aux créateurs.</p>

    <h4>7. Modifications des conditions d'utilisation</h4>
    <p>Ces conditions peuvent être modifiées à tout moment par <strong>3èmelieu SA</strong>. Les utilisateurs seront informés de toute modification via la plateforme, et les nouvelles conditions prendront effet dès leur publication. Il est recommandé de consulter régulièrement cette page pour être tenu au courant des éventuelles mises à jour.</p>

    <p>Pour toute question, veuillez nous contacter à l'adresse suivante : <strong>info@3emelieu.ch</strong>, ou en personne au <strong>2bis rue Baylon, Genève</strong>.</p>
`)}
                                                    >
                                                        Conditions d'utilisation
                                                    </Button>
                                                </div>
                                                <Button onClick={handlePayment}
                                                        className="btn btn-success btn-lg rounded-pill mb-3 px-5 py-3"
                                                        disabled={!canSelectAmount || selectedAmount === null}>
                                                    Soutenir ce projet
                                                </Button>
                                                <div style={{textAlign: 'center', marginTop: '20px'}}>
                                                    <h4>Partagez cette campagne :</h4>
                                                    <div className="social-buttons">
                                                        <a
                                                            href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="social-button"
                                                            style={{margin:10}}
                                                        >
                                                            <FacebookLogo size={32}/>
                                                        </a>
                                                        <a
                                                            href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(shareText)}&url=${encodeURIComponent(shareUrl)}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="social-button"
                                                            style={{margin:10}}
                                                        >
                                                            <TwitterLogo size={32}/>
                                                        </a>
                                                        <a
                                                            href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(shareUrl)}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="social-button"
                                                            style={{margin:10}}
                                                        >
                                                            <LinkedinLogo size={32}/>
                                                        </a>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                    <Row className="mt-4">
                                        <Col md={12}>
                                            <Card className="mb-3 bg-secondary text-white"
                                                  style={{borderRadius: '10px'}}>
                                                <Card.Body>
                                                    <Card.Title>Historique du portefeuille</Card.Title>
                                                    {userData.wallet_history?.length > 0 ? (
                                                        <ResponsiveContainer width="100%" height={300}>
                                                            <LineChart
                                                                data={userData.wallet_history}
                                                                margin={{top: 15, right: 30, left: 20, bottom: 5}}
                                                            >
                                                                <CartesianGrid strokeDasharray="2 2" stroke="#ddd"/>
                                                                <XAxis
                                                                    dataKey="date"
                                                                    stroke="#5257ff"
                                                                    tick={{fill: '#eee'}}
                                                                />
                                                                <YAxis
                                                                    stroke="#5257ff"
                                                                    tick={{fill: '#eee'}}
                                                                />
                                                                <Tooltip content={<CustomTooltip/>}/>
                                                                <Legend/>
                                                                <Line
                                                                    type="monotone"
                                                                    dataKey="amount"
                                                                    stroke="#00ff8c"
                                                                    strokeWidth={4}
                                                                    dot={{r: 8}}
                                                                    activeDot={{r: 8}}
                                                                />
                                                            </LineChart>
                                                        </ResponsiveContainer>
                                                    ) : (
                                                        <p>Aucun historique de portefeuille disponible.</p>
                                                    )}
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row className="mt-4">
                                        <Col md={12}>
                                            <Card className="bg-light" style={{borderRadius: '10px', paddingTop: 10}}>
                                                {/* courseData.CourseVideoUrl && (
                                                    <iframe
                                                        width="100%"
                                                        height="315"
                                                        src={courseData.CourseVideoUrl.replace('watch?v=', 'embed/')}
                                                        frameBorder="0"
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                        allowFullScreen
                                                    ></iframe>
                                                ) */}
                                                <Card.Body>
                                                    <Card.Title className="text-primary">Détails du Cours</Card.Title>
                                                    <Row>
                                                        <Col md={12}>
                                                            <Card.Title>{courseData?.Title}</Card.Title>
                                                            <hr/>
                                                            <div style={{fontSize: '1.2em', lineHeight: '1.6'}}
                                                                 dangerouslySetInnerHTML={{__html: courseData?.SmallDescription || ''}}/>
                                                            <hr/>
                                                            <h5>Plus d'informations sur la formation :</h5>
                                                            <div style={{
                                                                margin: 32,
                                                                display: 'flex',
                                                                justifyContent: 'center'
                                                            }}>
                                                                <a href={`https://www.3emelieu.ch/formations/${courseData.Slug}`}
                                                                   title={courseData.Title} target="_blank">
                                                                    <div
                                                                        className="btn btn-primary"
                                                                        style={{fontSize: '1.3em'}}>{courseData.Title}</div>
                                                                </a>
                                                            </div>
                                                            <hr/>
                                                            <div className="d-flex justify-content-around"
                                                                 style={{fontSize: '1.2em', flexWrap: 'wrap'}}>
                                                                <div>
                                                                    <strong
                                                                        style={{color: '#00ff8c'}}>Prix:</strong> {courseData?.Price} CHF
                                                                </div>
                                                                <div>
                                                                    <strong
                                                                        style={{color: '#00ff8c'}}>Durée:</strong> {courseData?.Duration} heures
                                                                </div>
                                                            </div>
                                                            <hr/>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>

                                    <Row className="mt-4">
                                        <Col md={12}>
                                            <DonorsList userData={userData} shareUrl={shareUrl} />
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className="embed-responsive embed-responsive-16by9">
                                <iframe
                                    className="embed-responsive-item"
                                    src="https://www.youtube.com/embed/ldf1BTHS67U"
                                    allowFullScreen
                                    title="3èmelieu SA à Genève - YouTube video"
                                ></iframe>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <footer className="footer bg-dark text-white py-4" style={{marginTop: 10}}>
                    <Container>
                        <Row>
                            <Col md={6}>
                                <p>&copy; 2024-2025 3èmelieu SA - Plateforme de financement participatif.<br/> Tous droits
                                    réservés.</p>
                            </Col>
                            <Col md={6} className="text-md-end">
                                <Button
                                    variant="link"
                                    className="text-white"
                                    onClick={() => handlePageModal("Conditions d'utilisation", `
    <h3>Conditions d'utilisation</h3>
    <p>Bienvenue sur la plateforme de financement participatif de <strong>3èmelieu SA</strong>, située au <strong>2bis rue Baylon, Genève, Suisse</strong>. En utilisant notre plateforme, vous acceptez les conditions générales décrites ci-dessous. Cette plateforme est spécifiquement destinée à soutenir les étudiants dans le financement de leur formation, ainsi que des projets éducatifs, culturels, d'innovation sociale et d'intégration professionnelle proposés par 3èmelieu SA.</p>

    <h4>1. Objectif de la plateforme</h4>
    <p>La plateforme permet aux étudiants de solliciter des financements pour leurs formations au sein de 3èmelieu SA, ainsi que pour des projets éducatifs, artistiques, culturels, ou sociaux. Les fonds collectés doivent être utilisés exclusivement pour ces objectifs, garantissant ainsi l'impact positif de chaque campagne.</p>

    <h4>2. Comptes utilisateur et sécurité</h4>
    <p>Seuls les étudiants qui souhaitent lancer une campagne de financement doivent créer un compte personnel. Ils sont responsables de la confidentialité de leurs informations de connexion et de toutes les activités effectuées sous leur compte. Nous recommandons vivement de sécuriser vos informations. Les donateurs ne sont pas tenus de créer un compte pour effectuer un don.</p>

    <h4>3. Gestion des fonds et objectifs de campagne</h4>
    <p>Tous les fonds collectés sont directement gérés par <strong>3èmelieu SA</strong>. Les étudiants ne reçoivent pas les fonds eux-mêmes. Si l'objectif de la campagne n'est pas atteint dans le délai imparti, les fonds sont automatiquement remboursés aux donateurs, sauf pour les campagnes lancées directement par <strong>3èmelieu SA</strong> dans le cadre de projets éducatifs ou culturels. Dans ces cas, les fonds seront utilisés pour soutenir les initiatives de l'institution.</p>

    <h4>4. Droits des contributeurs</h4>
    <p>Les contributeurs apportent leur soutien en toute confiance, sachant que les fonds sont sous la gestion directe de <strong>3èmelieu SA</strong>. En cas d'échec d'une campagne à atteindre son objectif dans le délai prévu, les fonds sont intégralement remboursés, sauf pour les campagnes institutionnelles initiées par <strong>3èmelieu SA</strong>. Toutefois, 3èmelieu SA n'est pas responsable des engagements non tenus dans les campagnes menées par des tiers ou des étudiants.</p>

    <h4>5. Propriété intellectuelle</h4>
    <p>Tout le contenu de cette plateforme, y compris les textes, images, vidéos et logos, est protégé et appartient à <strong>3èmelieu SA</strong> ou aux créateurs de projets. Toute utilisation, reproduction ou diffusion non autorisée de ces éléments est strictement interdite sans l'accord préalable de leurs propriétaires.</p>

    <h4>6. Responsabilité et garanties</h4>
    <p><strong>3èmelieu SA</strong> met tout en œuvre pour offrir une plateforme fiable et sécurisée. Cependant, elle ne peut garantir le succès des campagnes de financement ni le respect des délais ou des objectifs annoncés. La responsabilité de la bonne réalisation des projets financés incombe exclusivement aux créateurs de campagnes. <strong>3èmelieu SA</strong> n'est pas responsable des retards, des modifications ou de l'échec des projets, à l'exception des campagnes initiées directement par <strong>3èmelieu SA</strong>, pour lesquelles l'institution s'engage à utiliser les fonds conformément aux objectifs décrits.</p>

    <h4>7. Modifications des conditions d'utilisation</h4>
    <p>Ces conditions peuvent être modifiées à tout moment par <strong>3èmelieu SA</strong>. Les utilisateurs seront informés de toute modification via la plateforme, et les nouvelles conditions prendront effet dès leur publication. Il est recommandé de consulter régulièrement cette page pour être tenu au courant des éventuelles mises à jour.</p>

    <p>Pour toute question, veuillez nous contacter à l'adresse suivante : <strong>info@3emelieu.ch</strong>, ou en personne au <strong>2bis rue Baylon, Genève</strong>.</p>
`)}


                                >
                                    Conditions d'utilisation
                                </Button>
                                <Button
                                    variant="link"
                                    className="text-white"
                                    onClick={() => handlePageModal("Politique de confidentialité", `
    <h3>Politique de confidentialité</h3>
    <p>Chez <strong>3èmelieu SA</strong>, nous accordons une grande importance à la protection de votre vie privée et à la sécurité de vos données personnelles. Cette politique de confidentialité explique comment nous collectons, utilisons et protégeons les informations que vous nous fournissez en utilisant notre plateforme de financement participatif.</p>

    <h4>1. Données personnelles collectées</h4>
    <p>Pour utiliser notre plateforme de crowdfunding, vous devez créer un compte. Les informations suivantes sont requises :</p>
    <ul>
        <li>Nom</li>
        <li>Prénom</li>
        <li>Adresse email</li>
    </ul>
    <p>Ces informations sont nécessaires pour gérer votre compte, suivre vos contributions et vous tenir informé du statut des projets que vous soutenez.</p>

    <h4>2. Cookies</h4>
    <p>Notre plateforme ne sauvegarde pas de cookies. Nous ne suivons pas vos activités sur notre site à travers des cookies ou des trackers tiers.</p>

    <h4>3. Utilisation des données</h4>
    <p>Les données que vous fournissez sont strictement utilisées pour les finalités suivantes :</p>
    <ul>
        <li>Créer et gérer votre compte utilisateur.</li>
        <li>Permettre la participation aux campagnes de financement participatif.</li>
        <li>Vous envoyer des notifications et des mises à jour relatives aux projets que vous soutenez.</li>
    </ul>

    <h4>4. Sécurité des données</h4>
    <p>Nous prenons toutes les mesures raisonnables pour protéger vos données personnelles contre l'accès non autorisé, l'altération ou la divulgation. Cependant, la sécurité absolue des données sur internet ne peut être garantie.</p>

    <h4>5. Partage des données</h4>
    <p>Nous ne partageons, ne vendons, ni ne louons vos données personnelles à des tiers. Vos informations sont strictement réservées à l'usage de la plateforme et de ses créateurs de projets.</p>

    <h4>6. Vos droits</h4>
    <p>Vous avez le droit d'accéder à vos données personnelles, de les modifier ou de les supprimer à tout moment. Si vous souhaitez exercer ce droit, veuillez nous contacter à <strong>info@3emelieu.ch</strong>.</p>

    <h4>7. Modifications de la politique de confidentialité</h4>
    <p>Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. Les changements seront publiés sur cette page et vous en serez informé par email.</p>

    <p>Pour toute question concernant cette politique de confidentialité, veuillez nous contacter à <strong>info@3emelieu.ch</strong> ou nous rendre visite au <strong>2bis rue Baylon, Genève, Suisse</strong>.</p>
`)}

                                >
                                    Politique de confidentialité
                                </Button>
                                <Button
                                    variant="link"
                                    className="text-white"
                                    onClick={() => handlePageModal("Nous contacter", `
    <h3>Nous contacter</h3>
    <p>Que vous ayez des questions, des propositions ou simplement l'envie de partager vos réflexions, n'hésitez pas à nous contacter. Notre équipe est là pour répondre à toutes vos demandes et vous assister dans votre démarche éducative ou professionnelle.</p>

    <h4>Contactez-nous</h4>
    <p>Nous sommes disponibles pour répondre à vos demandes, que ce soit pour une inscription, une demande d'information, ou une collaboration potentielle. Nous sommes impatients de vous aider à atteindre vos objectifs.</p>

    <h4>Horaires d'ouverture</h4>
    <ul>
        <li><strong>Lundi :</strong> 09:00 - 18:00</li>
        <li><strong>Mardi :</strong> 09:00 - 18:00</li>
        <li><strong>Mercredi :</strong> 09:00 - 18:00</li>
        <li><strong>Jeudi :</strong> 09:00 - 18:00</li>
        <li><strong>Vendredi :</strong> 09:00 - 18:00</li>
        <li><strong>Samedi :</strong> Fermé</li>
        <li><strong>Dimanche :</strong> Fermé</li>
    </ul>

    <h4>Coordonnées</h4>
    <p><strong>Adresse :</strong> 2Bis rue Baylon, 1227 Carouge, Genève</p>
    <p><strong>Email :</strong> <a href="mailto:info@3emelieu.ch">info@3emelieu.ch</a></p>
    <p><strong>Téléphone :</strong> +41 22 700 03 07</p>

`)}

                                >
                                    Nous contacter
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </footer>
            </div>

            {/* Transaction Modal */
            }
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Merci pour votre contribution !</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {transactionDetails ? (
                        <div>
                            <p>Transaction ID: {transactionDetails.session.id}</p>
                            <p>Amount: {transactionDetails.session.amount_total / 100} CHF</p>
                            <p>Donor: {transactionDetails.customer.name}</p>
                        </div>
                    ) : (
                        <Spinner animation="border"/>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setShowModal(false)}>
                        Fermer
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Page Modal for static content */
            }
            <Modal size="lg" show={showPageModal} onHide={() => setShowPageModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div dangerouslySetInnerHTML={{__html: modalContent}}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowPageModal(false)}>
                        Fermer
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
        ;
};

export default Crowdfunding;
